import React, { useCallback, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import {
     CModal,
     CModalBody,
     CButton,
     CImg,
} from '@coreui/react';
import AceEditor from 'react-ace';
import PropTypes from 'prop-types';
import wwwAnimationData from '../../../assets/lottie-files/www.json';
import successAnimationData from '../../../assets/lottie-files/success.json';

import CommonLottie from './CommonLottie';
import { getDLScript } from '../../../utils';

const PopupAddDomain = ({ domainSelected, children }) => {
     return (
          <div className='add-domain'>
               <h3>Add Domain</h3>
               <p className='description'>We're about to add <span className="c-blue">{domainSelected}</span> to your <br></br>ListenLayer account. Click the button below to complete this<br></br> action and we'll refresh your test session.</p>
               <div className="actions position-relative d-inline-block">
                    {children}
               </div>
          </div>
     )
};

const PlaceScriptManually = ({ onAccept, onClose }) => {
     const activeAccount = useSelector(state => state.subscriber.activeAccount);
     const theScript = activeAccount && getDLScript(activeAccount.id);
     const [firstCopy, setFirstCopy] = useState(false);

     const placedTheCode = useCallback(() => {
          if (onAccept) {
               onAccept();
          }
          onClose();
     }, [onClose, onAccept]);

     const copyFirstScript = useCallback(() => {
          setFirstCopy(true);
     }, []);

     useEffect(() => {
          if (firstCopy) {
               setTimeout(() => {
                    setFirstCopy(false);
               }, 2000);
          }
     }, [firstCopy]);

     return (
          <div className="account-script-manually">
               <h3>Add Script</h3>
               <p>{`Your ListenLayer script must load on all pages that you want to track. Copy the script below and add it to the <head> tag of every page or publish it as a tag in Google Tag Manager.`}</p>
               <div className="script-code">
                    <div className="d-flex align-items-center copy-script">
                         {firstCopy ? <div className="icon-copy">Copied</div> : <div className="icon-copy">Click to Copy</div>}
                         <CopyToClipboard text={theScript} onCopy={copyFirstScript}>
                              <CButton>
                                   <CImg src="/assets/images/icon-copy-v2.png" height="19" width="19" />
                              </CButton>
                         </CopyToClipboard>
                    </div>
                    <div className="script">
                         <AceEditor
                              placeholder="No Code Preview"
                              mode="html"
                              theme="solarized_light"
                              name="blah2"
                              fontSize={16}
                              width="100%"
                              height="164px"
                              showPrintMargin={true}
                              showGutter={false}
                              highlightActiveLine={false}
                              readOnly={true}
                              value={theScript}
                              wrapEnabled={true}
                              setOptions={{
                                   enableBasicAutocompletion: false,
                                   enableLiveAutocompletion: false,
                                   enableSnippets: false,
                                   showLineNumbers: false,
                                   useWorker: false,
                              }}
                         />
                    </div>
               </div>
               <CButton className="btn-save" onClick={placedTheCode}>
                    I’VE PLACED THE CODE
               </CButton>
               <div>
                    <div className="btn-cancel" onClick={onClose}>Cancel, go back</div>
               </div>
          </div>
     );
};

const PopupHasAction = ({ onClose, props }) => {
     const { show, actionType, onAccept, domainSelected, onAcceptNewDomain, onAcceptAddScriptPreview } = props;

     const renderActionComponent = (action) => {
          let content = '';

          switch (action) {
               case 'addDomain':
                    content = <PopupAddDomain domainSelected={domainSelected}>
                         <CButton
                              className="btn-info"
                              onClick={onAccept}
                         >
                              Add Domain
                         </CButton>
                         <div>
                              <div className="btn-cancel" onClick={onClose}>Cancel, go back</div>
                         </div>
                    </PopupAddDomain>
                    break;
               case 'addDomainFail':
                    content = <PopupAddDomain domainSelected={domainSelected}>
                         <div className="cannot-access">
                              <div className="title">
                                   <p className='mb-0'>You entered this: <span className="c-black">{domainSelected}</span></p>
                                   <p>Which is returning a <span className="c-red">5xx error</span></p>
                              </div>
                              <p>
                                   Note: If you are testing ListenLayer on a staging or dev website that is inaccessible, or a domain that doesn't have a homepage, our system may not see and verify the data. We recommend placing ListenLayer on your production website, just like you
                                   would do with a Google Tag Manager container.
                              </p>
                         </div>
                         <CButton
                              className="btn-save"
                              onClick={onAcceptNewDomain}
                         >
                              Enter a new website
                         </CButton>
                         <div>
                              <div className="btn-cancel" onClick={onClose}>Cancel, go back</div>
                         </div>
                    </PopupAddDomain>
                    break;
               case 'addDomainSuccess':
                    content = <PopupAddDomain domainSelected={domainSelected}>
                         <CommonLottie animationData={successAnimationData} />
                         <p>Add domain success!</p>
                         <CButton
                              className="btn-info"
                              onClick={onAcceptAddScriptPreview}
                         >Reset test</CButton>
                    </PopupAddDomain>
                    break;
               case 'addScriptManual':
                    content = <PlaceScriptManually onClose={onClose} />
                    break;
               case 'addScriptManualPreview':
                    content = <PlaceScriptManually onClose={onClose} onAccept={onAcceptAddScriptPreview} />
                    break;
               case 'loading':
                    content = <PopupAddDomain domainSelected={domainSelected}>
                         <CommonLottie animationData={wwwAnimationData} />
                         <span className="foot-note mb-2">Checking your domain...</span>
                    </PopupAddDomain>;
                    break;
               case 'verifying':
                    content = <PopupAddDomain domainSelected={domainSelected}>
                         <CommonLottie animationData={wwwAnimationData} />
                         <span className="foot-note mb-2">Verifying your domain...</span>
                    </PopupAddDomain>;
                    break;
               case 'upgrade':
                    content = <PopupAddDomain domainSelected={domainSelected}>
                         <p className="c-red">Your number of domain has reach to limit! Please upgrade your plan to get more.</p>
                         <div>
                              <div className="btn-cancel" onClick={onClose}>Cancel, go back</div>
                         </div>
                    </PopupAddDomain>;
                    break;
               default:
                    break;
          }

          return content;
     }

     return (
          <div className="confirm-save-popup">
               <CModal
                    show={show}
                    onClose={onClose}
                    color="primary"
                    centered
                    closeOnBackdrop={actionType !== 'addDomainSuccess'}
               >
                    <CModalBody className="text-center confirm-save">
                         {renderActionComponent(actionType)}
                    </CModalBody>
               </CModal>
          </div>
     )
}

PopupHasAction.propTypes = {
     onClose: PropTypes.func,
     props: PropTypes.object
}

export default PopupHasAction
