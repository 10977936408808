import { CButton, CCard, CCardBody } from '@coreui/react';
import React, { useContext, useState } from 'react';
import { CLIENT_SWITCH_SEND_EVENT_TO_SERVER_SIDE, DESTINATION_CODE, SWITCH_TYPE_WIZARD_STEP } from '../../../../../../constants';
import { useActiveDestination } from '../../../../../../helpers/customHooks';
import Waiting from '../../../../../general/Loadings/Waiting';
import { SwitchTypeWizardBody, SwitchTypeWizardContext } from '../SwitchTypeWizard';
import RequiredValueInput from './RequireValueInput';
import { callTokenApi } from '../../../../../../apiCaller';
import { useSelector, useDispatch } from 'react-redux';
import { toastError } from '../../../../../../utils';
import { setAccountDestination, setSwitchTypeWizard } from '../../../../../../actions/subscriber';

const GetStarted = () => {
     const dispatch = useDispatch();
     const { setCurrentStep, setShowCancelPopup, closeWizard, isGTM, isServerSide, isEither, handleUpdateSwitchType, destinationName, handleReloadData } =
          useContext(SwitchTypeWizardContext);
     const activeDestination = useActiveDestination();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const accountDestination = useSelector((state) => state.subscriber.accountDestination);
     const initUsingType = (accountDestination && accountDestination.usingType) || 'gtm';
     const isGA4 = accountDestination && accountDestination.destinationCode === DESTINATION_CODE.GA4;
     const switchTypeWizard = useSelector((state) => state.subscriber.switchTypeWizard);
     const { sendTo, switchOptionEither } = switchTypeWizard;
     const { generalSettings } = activeDestination;
     const requireFields = [];
     const [isLoading, setIsLoading] = useState(false);

     if (!isGTM) {
          switch (activeDestination.code) {
               case DESTINATION_CODE.GA4:
                    if (!generalSettings) {
                         requireFields.push('a Measurement ID', 'an API Secret');
                    } else {
                         if (!generalSettings.measurementId) {
                              requireFields.push('a Measurement ID');
                         }
                         if (!generalSettings.apiSecret) {
                              requireFields.push('an API Secret');
                         }
                    }
                    break;
               case DESTINATION_CODE.FACEBOOK_ADS:
                    if (!generalSettings) {
                         requireFields.push('a Pixel ID', 'an Access Code');
                    } else {
                         if (!generalSettings.pixelId) {
                              requireFields.push('a Pixel ID');
                         }
                         if (!generalSettings.accessToken) {
                              requireFields.push('an Access Code');
                         }
                    }
                    break;
               default:
                    break;
          }
     }

     const goToNextStep = () => {
          if (isGA4 && isEither && switchOptionEither) {
               const usingType = 'either';

               if (initUsingType === 'server') {
                    const query = `?group=dataAction&destinationId=${activeDestination.id}`;
                    setIsLoading(true);
                    callTokenApi(`${CLIENT_SWITCH_SEND_EVENT_TO_SERVER_SIDE}/${activeAccount.id}${query}`, 'POST')
                         .then((response) => {
                              if (response.status === 200) {
                                   dispatch(setAccountDestination({ ...accountDestination, usingType }));
                                   dispatch(setSwitchTypeWizard({ ...switchTypeWizard, usingType }));
                              } else {
                                   toastError(response);
                              }
                         })
                         .finally(() => {
                              setIsLoading(false);
                              setCurrentStep(SWITCH_TYPE_WIZARD_STEP.migrateSuccess);
                         });
               } else {
                    handleUpdateSwitchType({ initStep: SWITCH_TYPE_WIZARD_STEP.getStarted, usingType });
                    setCurrentStep(SWITCH_TYPE_WIZARD_STEP.migrateSuccess);
               }
               handleReloadData();
          } else {
               setCurrentStep(SWITCH_TYPE_WIZARD_STEP.migrate);
          }
     };

     const onSubmitStartButton = (e) => {
          e.preventDefault();
          goToNextStep();
     };

     const getContent = () => {
          let content = {};

          if (isGTM || (isEither && sendTo === 'gtm')) {
               content.heading = 'Client Side Tagging';
               content.subHeading = 'Client Side Tagging';
               content.processStep1 = 'client side';
               content.processStep2 = `Distribute your changes to GTM (you'll need publishing rights)`;
          }

          if (isServerSide || (isEither && sendTo === 'server')) {
               content.heading = 'Server Side Tagging';
               content.subHeading = 'Server Side Tagging';
               content.processStep1 = 'server side';
               content.processStep2 = `Clean up your GTM implementation to remove duplicates (optional)`;
          }

          if (isEither && sendTo === 'either') {
               content.heading = 'Each Data Action';
               content.subHeading = 'config for each Data Action';
               content.processStep1 = 'server side or client site on each Data Action';
               content.processStep2 = `Clean up your GTM implementation to remove duplicates (optional)`;
               content.processStep3 = `Distribute your changes to GTM (you'll need publishing rights)`;
          }

          if (switchOptionEither) {
               content.heading = 'Each Data Action';
               content.subHeading = 'both Server Side Tagging and Client Side Tagging';
               content.duringProcess = 'All Data Actions will be retained in the current Side Tagging.';
          }

          if (!content.heading) {
               content.heading = isGTM ? 'Client Side Tagging' : 'Server Side Tagging';
          }

          if (!content.subHeading) {
               content.subHeading = isGTM ? 'Client Side Tagging' : 'Server Side Tagging';
          }

          if (!content.processStep1) {
               content.processStep1 = isGTM ? 'client side' : 'server side';
          }

          if (!content.processStep2) {
               content.processStep2 = isGTM
                    ? `Distribute your changes to GTM (you'll need publishing rights)`
                    : `Clean up your GTM implementation to remove duplicates (optional)`;
          }

          return content;
     };

     const contentGetStarted = getContent();

     return (
          <SwitchTypeWizardBody onBackBtnClicked={() => closeWizard()}>
               {requireFields.length > 0 ? (
                    <RequiredValueInput requireFields={requireFields} />
               ) : (
                    <CCard className="get-started">
                         <CCardBody>
                              <h3>Switch to {contentGetStarted.heading} </h3>
                              <p className="des">
                                   {`Configuring ListenLayer to send Conversions and other events to ${destinationName} using ${contentGetStarted.subHeading}.`}
                              </p>
                              {(isGTM || (isEither && sendTo === 'gtm')) && (
                                   <span className="foot-note mb-4">
                                        We don't recommend switching from Server Side to client-side tagging, but if this is something you need, we
                                        can walk through the process.
                                   </span>
                              )}

                              {contentGetStarted.duringProcess ? (
                                   <p>{contentGetStarted.duringProcess}</p>
                              ) : (
                                   <span className="foot-note mb-1 text-justify">During this process, you'll complete the following: </span>
                              )}

                              {!switchOptionEither && (
                                   <div className="changes-box">
                                        <div className="change-item">
                                             <p>1. Migrate your existing Data Actions to {contentGetStarted.processStep1}.</p>
                                        </div>
                                        <div className="change-item">
                                             <p>2. {contentGetStarted.processStep2} </p>
                                        </div>
                                        {contentGetStarted.processStep3 && (
                                             <div className="change-item">
                                                  <p>3. {contentGetStarted.processStep3} </p>
                                             </div>
                                        )}
                                   </div>
                              )}
                              <span className="foot-note px-3">
                                   This process can take anywhere from 2 to 5 minutes. You'll have a chance to review and cancel changes.
                              </span>
                              <CButton className="btn-save" type="submit" style={{ width: '40%' }} onClick={onSubmitStartButton}>
                                   <Waiting isLoading={isLoading}>LET'S START</Waiting>
                              </CButton>
                              <CButton className="btn-navigation-step back" onClick={() => setShowCancelPopup(true)}>
                                   No thanks, go back
                              </CButton>
                         </CCardBody>
                    </CCard>
               )}
          </SwitchTypeWizardBody>
     );
};

export default GetStarted;
