import React, { useContext, useEffect } from 'react';
import { CCard, CCardBody } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';

import { GTMWizardBody, GTMWizardContext } from '.';
import { callTokenApi } from '../../../../../apiCaller';
import { API_CLIENT_DESTINATIONS_GTM_BY_RULE, API_CLIENT_DESTINATIONS_GTM_CHANGES, API_CLIENT_DESTINATIONS_GTM_PUBLISH_OBJECT, GTM_ACTIONS, GTM_STATUSES_IN_LL, GTM_WIZARD_STEPS, GTM_WIZARD_TYPE } from '../../../../../constants';
import { useCancelToken, useHasPublishGTMPermission } from '../../../../../helpers/customHooks';
import CommonLottie from '../../../../general/lottie/CommonLottie';
import llConnectToGTMAnimationData from '../../../../../assets/lottie-files/ll-connect-to-gtm.json';
import { reloadConversionRules, reloadDataActions, reloadTriggerRules, setGTMVariablesInLL, setGTMWizard, setSubscriberState, setSwitchTypeWizard } from '../../../../../actions/subscriber';

const GTMMakeChanges = () => {
     const dispatch = useDispatch();
     const { stepsData, setStepsData, setCurrentStep } = useContext(GTMWizardContext);
     const activeAccount = useSelector(state => state.subscriber.activeAccount);
     const { currentContainer } = activeAccount.gtmInfo;
     const gtmWizard = useSelector(state => state.subscriber.gtmWizard);
     const switchTypeWizard = useSelector((state) => state.subscriber.switchTypeWizard);
     const getCancelToken = useCancelToken();
     const { gtmHasUnpublishedChanges, workspaceName, workspacePath, doPublish, gtmHasLLWorkspace } = stepsData;
     const hasPublishPermission = useHasPublishGTMPermission();

     const handleSuccess = async () => {
          if (typeof gtmWizard.data.doNext === 'function') {
               await gtmWizard.data.doNext();
          }
          
          switch (gtmWizard.type) {
               case GTM_WIZARD_TYPE.TRIGGER:
                    dispatch(reloadTriggerRules());
                    break;
               case GTM_WIZARD_TYPE.TRIGGER_CONVERSION:
                    // case GTM_WIZARD_TYPE.DESTINATION_CONVERSION:
                    dispatch(reloadConversionRules());
                    break;
               case GTM_WIZARD_TYPE.DATA_ACTION:
                    // Reload data actions to make them update with the new GTM status
                    dispatch(reloadDataActions());
                    break;
               default:
          }

          if (!hasPublishPermission && doPublish) {
               setCurrentStep(GTM_WIZARD_STEPS.gtmNoPublishingRights);
               return;
          }

          setCurrentStep(GTM_WIZARD_STEPS.gtmSuccess);
     }

     const handleGeneralActions = () => {
          let postData = {
               accountId: activeAccount.id,
               container: {
                    containerId: currentContainer.containerId,
                    path: currentContainer.path,
                    publicId: currentContainer.publicId
               },
               name: gtmWizard.data.name,
               type: gtmWizard.type,
               action: 'Make Changes',
               gtmHasUnpublishedChanges,
               workspaceName,
               workspacePath,
               hasPublishPermission,
               doPublish,
               isUpdating: gtmWizard.data.isUpdating,
               gtmHasLLWorkspace
          };
          switch (gtmWizard.type) {
               case GTM_WIZARD_TYPE.VARIABLE:
                    postData.variableListenerId = gtmWizard.data.listenerId;
                    break;
               case GTM_WIZARD_TYPE.TRIGGER:
               case GTM_WIZARD_TYPE.TRIGGER_CONVERSION:
               case GTM_WIZARD_TYPE.DATA_ACTION:
                    if (gtmWizard.data.isLookupChild) {
                         postData.ruleId = gtmWizard.data.ruleId;
                         postData.isLookupChild = gtmWizard.data.isLookupChild;
                         postData.lookupChildId = gtmWizard.data.id;
                    } else {
                         postData.ruleId = gtmWizard.data.id;
                    }

                    break;
               case GTM_WIZARD_TYPE.DESTINATION_CONVERSION:
                    postData.destinationConversion = gtmWizard.data;
                    break;
               case GTM_WIZARD_TYPE.DESTINATION_ECOMMERCE:
                    postData.destinationConversion = gtmWizard.data;
                    break;
               default:
          }

          callTokenApi(API_CLIENT_DESTINATIONS_GTM_CHANGES, 'POST', postData, getCancelToken())
               .then(response => {
                    if (response) {
                         dispatch(setSubscriberState({ gtmVariablesInLL: null, gtmTriggersInLL: null, gtmTagsInLL: null, gtmChangelog: null }));

                         if (response.status === 200) {
                              handleSuccess();
                         } else {
                              if (response.data.isRunOutOfWorkspace) {
                                   setStepsData({ isRunOutOfWorkspace: true });
                              }

                              if (response.data.publishFailed) {
                                   handleSuccess();
                                   setStepsData({ publishFailed: true });
                              }

                              if (response.data.duplicateTriggerName) {
                                   setStepsData({ duplicateTriggerName: response.data.duplicateTriggerName });
                              }
                              if (response.data.duplicateTagName) {
                                   setStepsData({ duplicateTagName: response.data.duplicateTagName });
                              }

                              setCurrentStep(GTM_WIZARD_STEPS.gtmUnknownFailure);
                         }
                    }
               })
     };

     const handleDelete = () => {
          const gtmDeleteByRuleData = {
               accountId: activeAccount.id,
               ruleId: gtmWizard.data.id,
               type: gtmWizard.type,
               containerId: currentContainer.containerId,
               containerPath: currentContainer.path,
               action: GTM_ACTIONS.MAKE_CHANGES,
               hasPublishPermission
          };

          callTokenApi(API_CLIENT_DESTINATIONS_GTM_BY_RULE, 'DELETE', gtmDeleteByRuleData)
               .then((response) => {
                    dispatch(setSubscriberState({ gtmVariablesInLL: null, gtmTriggersInLL: null, gtmTagsInLL: null, gtmChangelog: null }));

                    if (response.status === 200) {
                         handleSuccess();
                    } else {
                         if (response.data.isRunOutOfWorkspace) {
                              setStepsData({ isRunOutOfWorkspace: true });
                         }

                         if (response.data.publishFailed) {
                              setStepsData({ publishFailed: true });
                         }

                         setCurrentStep(GTM_WIZARD_STEPS.gtmUnknownFailure);
                    }
               })
     };

     const handlePause = () => {
          const gtmDeleteByRuleData = {
               accountId: activeAccount.id,
               ruleId: gtmWizard.data.id,
               type: gtmWizard.type,
               containerId: currentContainer.containerId,
               containerPath: currentContainer.path,
               action: GTM_ACTIONS.MAKE_CHANGES,
               pause: gtmWizard.data.pause,
               hasPublishPermission,
          };

          callTokenApi(API_CLIENT_DESTINATIONS_GTM_BY_RULE, 'POST', gtmDeleteByRuleData)
               .then((response) => {
                    dispatch(setSubscriberState({ gtmVariablesInLL: null, gtmTriggersInLL: null, gtmTagsInLL: null, gtmChangelog: null }));

                    if (response.status === 200) {
                         handleSuccess();
                    } else {
                         if (response.data.isRunOutOfWorkspace) {
                              setStepsData({ isRunOutOfWorkspace: true });
                         }

                         if (response.data.publishFailed) {
                              setStepsData({ publishFailed: true });
                         }

                         setCurrentStep(GTM_WIZARD_STEPS.gtmUnknownFailure);
                    }
               })
     };

     const handlePublishObject = () => {
          let gtmPublishData = {
               accountId: activeAccount.id,
               type: gtmWizard.type,
               containerId: currentContainer.containerId,
               containerPath: currentContainer.path,
               gtmObjInLLId: gtmWizard.data.gtmObjInLLId
          };

          switch (gtmWizard.type) {
               case GTM_WIZARD_TYPE.TRIGGER:
               case GTM_WIZARD_TYPE.TRIGGER_CONVERSION:
               case GTM_WIZARD_TYPE.DATA_ACTION:
                    gtmPublishData.ruleId = gtmWizard.data.id;
                    break;
               case GTM_WIZARD_TYPE.DESTINATION_CONVERSION:
               case GTM_WIZARD_TYPE.DESTINATION_ECOMMERCE:
                    gtmPublishData.destinationId = gtmWizard.data.destinationId;
                    break;
               default:
          }
          callTokenApi(API_CLIENT_DESTINATIONS_GTM_PUBLISH_OBJECT, 'POST', gtmPublishData)
               .then((response) => {
                    if (response.status === 200) {
                         const { gtmPublicVariable } = response.data
                         if(gtmPublicVariable){
                              dispatch(setGTMWizard({ ...gtmWizard, gtmPublicVariable: gtmPublicVariable }));
                         }
                         switch (gtmWizard.type) {
                              case GTM_WIZARD_TYPE.VARIABLE:
                                   dispatch(setGTMVariablesInLL(null));
                                   break;
                              case GTM_WIZARD_TYPE.TRIGGER:
                                   dispatch(reloadTriggerRules());
                                   break;
                              case GTM_WIZARD_TYPE.TRIGGER_CONVERSION:
                                   dispatch(reloadConversionRules());
                                   break;
                              case GTM_WIZARD_TYPE.DATA_ACTION:
                                   // Reload data actions to make them update with the new GTM status
                                   dispatch(reloadDataActions());
                                   if (switchTypeWizard && switchTypeWizard.gtmTagLL.length) {
                                        dispatch(setSwitchTypeWizard({gtmTagLL: switchTypeWizard.gtmTagLL.filter(item => item.ruleId !== gtmWizard.data.id)}))
                                   }
                                   break;
                              default:
                         }

                         setStepsData({ doPublish: true });
                         setCurrentStep(GTM_WIZARD_STEPS.gtmSuccess);

                         if (typeof gtmWizard.data.doNext === 'function') {
                              gtmWizard.data.doNext();
                         }
                    } else {
                         if (response.data.noPublishPermission) {
                              setCurrentStep(GTM_WIZARD_STEPS.gtmNoPublishingRights);
                         } else {
                              setStepsData({ publishFailed: true });
                              setCurrentStep(GTM_WIZARD_STEPS.gtmUnknownFailure);
                         }
                    }
               })
     };

     const handleFirstLoad = () => {
          if (gtmWizard.data.isUpdating) {
               return handleGeneralActions();
          }

          switch (gtmWizard.data.status) {
               case GTM_STATUSES_IN_LL.MODIFIED_IN_LL:
                    handleGeneralActions();
                    break;
               case GTM_STATUSES_IN_LL.REMOVE_FROM_GTM:
                    handleDelete();
                    break;
               case GTM_STATUSES_IN_LL.PAUSE_FROM_GTM:
                    handlePause();
                    break;
               case GTM_STATUSES_IN_LL.UNPUBLISHED:
                    handlePublishObject();
                    break;
               default:
                    handleGeneralActions();
          }
     };

     useEffect(handleFirstLoad, []);

     return (
          <GTMWizardBody>
               <CCard className="account-gtm making-changes">
                    <CCardBody>
                         <h3>Making changes in GTM</h3>
                         <CommonLottie animationData={llConnectToGTMAnimationData} />
                         <p>We're working on making these changes; please wait on this screen (it will only take a few seconds).</p>
                    </CCardBody>
               </CCard>
          </GTMWizardBody>
     )
};

export default GTMMakeChanges;