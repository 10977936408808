import React, { useEffect, useState } from 'react';
import { Message } from './Message.js';
import { CButton, CRow, CCol, CImg } from '@coreui/react';
import { GUIDESTEPS } from './setupGuide.js';
import { API_CLIENT_ACCOUNT_COMPLETED_SETUP_GUIDE } from '../../../../constants/apiRest.js';
import { callTokenApi } from '../../../../apiCaller.js';
import { useDispatch } from 'react-redux';
import { setParentSetupGuideDataCompleted } from '../../../../actions/subscriber.js';
import BoxFeature from './BoxFeature.js';

export const CustomOptional = (props) => {
     const { nextTour, dataFeature, activeAccountId, dataStepCompleted } = props;
     const [featureSuccess, setFeatureSuccess] = useState(false);
     const dispatch = useDispatch();

     const showAnimationRoadmap = localStorage.getItem('showAnimationRoadmap');
     const notShowStepAnimation = localStorage.getItem('customOptional');

     useEffect(() => {
          setTimeout(() => {
               localStorage.setItem('customOptional', true);
               localStorage.removeItem('showAnimationRoadmap');
          }, 2000)
     }, []); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          const numberStepCompleted = dataFeature.feature.filter((feature) => feature.status);
          if (numberStepCompleted.length === dataFeature.feature.length) {
               setFeatureSuccess(true);
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [dataFeature]);

     const onClickNextStep = () => {
          nextTour(GUIDESTEPS.STEP3);
          localStorage.setItem('customOptional', true)
          localStorage.setItem('showAnimationRoadmap', true);
          const data = {
               accountId: activeAccountId,
               data: { customImplementation: true },
          };
          callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_SETUP_GUIDE, 'PUT', data).then((response) => {
               if (response.status === 200) {
                    dispatch(setParentSetupGuideDataCompleted({ customImplementation: true }));
               }
          });
     };

     const completeStep = dataStepCompleted ? (dataStepCompleted.completedSteps ? dataStepCompleted.completedSteps.customImplementation : false) : false;
     const isShowAnimation = !!(!notShowStepAnimation && !completeStep && showAnimationRoadmap);

     return (
          <CRow className='guide-row'>
               <CCol md='1'>
                    <CImg src='/assets/images/avtAdSetupGuide.png' height='60' width='60'  className={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-1s' : ''} />
               </CCol>
               <CCol md='11'>
                    <div className={`tour-guide show setup-guide`}>
                         <div className='tour-guide-content content'>
                              <Message text={'Great work!'} 
                                   hasLoading={isShowAnimation}
                                   animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-1s' : ''} 
                                   animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-2s' : ''} />
                              <Message text={'Before we can configure your goals, it looks like you have enabled one or more Listeners that require customization.'} 
                                   hasLoading={isShowAnimation} 
                                   animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-3s animate__faster-3s' : ''} 
                                   animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-7s' : ''} />
                              <Message text={'Complete these steps to Continue.'} 
                                   hasLoading={isShowAnimation} 
                                   animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-8s' : ''} 
                                   animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-10s' : ''} />
                              <div className={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-10s' : ''}>
                                   {dataFeature.feature.map((feature, index) => (
                                        <BoxFeature feature={feature} key={index} />
                                   ))}
                              </div>
                              {featureSuccess && (
                                   <>
                                        <Message text={"Nice work! Select NEXT when you're ready to move forward."} 
                                             hasLoading
                                             animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-11s' : ''}
                                             animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-12s animate__faster-300ms' : ''}
                                        />
                                        <div className={`buttons justify-content-end ${isShowAnimation ? ' animate__animated animate__fadeIn animate__delay-13s animate__faster-300ms' : ''}`}>
                                             <CButton className='next-button roadmap' onClick={() => onClickNextStep()}>
                                                  Next
                                             </CButton>
                                        </div>
                                   </>
                              )}
                         </div>
                    </div>
               </CCol>
          </CRow>
     );
};
