import React, { useContext } from 'react';
import { CButton, CCard, CCardBody } from '@coreui/react';
import { SwitchTypeWizardBody, SwitchTypeWizardContext } from '../SwitchTypeWizard';
import { useSelector } from 'react-redux';

const Finally = () => {
     const { closeWizard, isGTM, destinationName, isServerSide, isEither, } = useContext(SwitchTypeWizardContext);
     const switchTypeWizard = useSelector((state) => state.subscriber.switchTypeWizard);
     const {sendTo, switchOptionEither} = switchTypeWizard
     const getContent = () => {
          let content = ''

          if(isGTM || (isEither && sendTo === 'gtm')) {
               content = 'Client Side!' 
          }

          if(isServerSide || (isEither && sendTo === 'server')) {
               content = 'Server Side!' 
          }

          if((isEither && sendTo === 'either') || switchOptionEither) {
               content = 'Client site and Server Side!'   
          }

          if(!content){
               content = isGTM ? 'Client Side!' : 'Server Side!'
          }

          return content
     }

     const content = getContent()

     return (
          <SwitchTypeWizardBody shouldHideBackBtn={true}>
               <CCard className='finally'>
                    <CCardBody>
                         <h3>Your Changes Have Been Published</h3>
                         <p className='des'>
                              {destinationName} is now running {content} You can close this wizard.
                         </p>
                         <CButton className='btn-save' type='submit' style={{ width: '40%' }} onClick={closeWizard}>
                              CLOSE
                         </CButton>
                    </CCardBody>
               </CCard>
          </SwitchTypeWizardBody>
     );
};

export default Finally;
