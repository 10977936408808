import React, { useEffect } from 'react';
import { Message } from './Message.js';
import { CButton, CCol, CImg, CRow } from '@coreui/react';
import { STEP_GUIDE } from '../../../../constants/path.js';
import { API_CLIENT_ACCOUNT_COMPLETED_SETUP_GUIDE } from '../../../../constants/apiRest.js';
import { useDispatch, useSelector } from 'react-redux';
import { setParentSetupGuideDataCompleted } from '../../../../actions/subscriber.js';
import { callTokenApi } from '../../../../apiCaller.js';
export const GuideGetStarted = (props) => {
     const dispatch = useDispatch();
     const { featureSuccess, nextTour, dataStepCompleted } = props;
     const activeAccountId = useSelector((state) => state.subscriber.activeAccount.id);
     const name = useSelector((state) => state.theme.user.firstName);

     const showAnimationRoadmap = localStorage.getItem('showAnimationRoadmap');
     const notShowStepAnimation = localStorage.getItem('guideGetStarted');

     useEffect(() => {
          setTimeout(() => {
               localStorage.setItem('guideGetStarted', true)
               localStorage.removeItem('showAnimationRoadmap')
          }, 2000)
     }, []); // eslint-disable-line react-hooks/exhaustive-deps

     const onClickNextStep = () => {
          nextTour(STEP_GUIDE.STEP1);
          localStorage.setItem('showAnimationRoadmap', true);
          localStorage.setItem('guideGetStarted', true)
          const data = {
               accountId: activeAccountId,
               data: { guideGetStarted: true },
          };
          callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_SETUP_GUIDE, 'PUT', data).then((response) => {
               if (response.status === 200) {
                    dispatch(setParentSetupGuideDataCompleted({ guideGetStarted: true }));
               }
          });
     };

     const completeStep = dataStepCompleted ? (dataStepCompleted.completedSteps ? dataStepCompleted.completedSteps.guideGetStarted : false) : false;

     const isShowAnimation = !!(!notShowStepAnimation && !completeStep && showAnimationRoadmap);

     return (
          <CRow className='guide-row'>
               <CCol md='1'>
                    <CImg
                         src='/assets/images/avtAdSetupGuide.png'
                         height='60'
                         width='60'
                         className={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-3s' : ''}
                    />
               </CCol>
               <CCol md='11'>
                    <div className={`tour-guide show setup-guide`}>
                         <div className='tour-guide-content content'>
                              <Message
                                   text={`Hi ${name}, welcome to ListenLayer! My name is Larry, and I'm here to make sure you accomplish your goals.`}
                                   hasLoading={isShowAnimation}
                                   animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-3s animate__faster-3s' : ''}
                                   animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-7s' : ''}
                              />
                              <Message
                                   text={
                                        'I realized you want to dive into the interface and get started! I strongly recommend you follow my lead to complete a few steps.'
                                   }
                                   hasLoading={isShowAnimation}
                                   animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-8s animate__faster-3s' : ''}
                                   animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-12s' : ''}
                              />
                              <Message
                                   text={'Click “NEXT” when you’re ready to dive in.'}
                                   hasLoading={isShowAnimation}
                                   animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-13s' : ''}
                                   animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-15s' : ''}
                              />
                              {featureSuccess && (
                                   <div className={`buttons justify-content-end ${isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-16s' : ''}`}>
                                        <CButton className='next-button roadmap' onClick={() => onClickNextStep()}>
                                             Next
                                        </CButton>
                                   </div>
                              )}
                         </div>
                    </div>
               </CCol>
          </CRow>
     );
};
