import React, { useContext, useState } from 'react';
import { CButton, CCard, CCardBody } from '@coreui/react';
import { SwitchTypeWizardBody, SwitchTypeWizardContext } from '../SwitchTypeWizard';
import Waiting from '../../../../../general/Loadings/Waiting';
import { API_VERSION_PUBLISH, SWITCH_TYPE_WIZARD_STEP } from '../../../../../../constants';
import { callTokenApi } from '../../../../../../apiCaller';
import { useDispatch, useSelector } from 'react-redux';
import { setNumberOfHistories, setPublishingVersion } from '../../../../../../actions/pusher';
import { resetAllPreview, setVersionPublish } from '../../../../../../actions/subscriber';
import { toast } from 'react-toastify';

const GTMSuccess = () => {
     const dispatch = useDispatch();
     const { closeWizard, setCurrentStep, stepsData, isGTM, isEither } = useContext(SwitchTypeWizardContext);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const versionPublish = useSelector((state) => state.subscriber.versionPublish);
     const numberOfHistories = useSelector((state) => state.subscriber.numberOfHistories);
     const switchTypeWizard = useSelector((state) => state.subscriber.switchTypeWizard);
     const {sendTo, switchOptionEither} = switchTypeWizard

     const isUnpublished = versionPublish.isPublish === 'enable' || parseInt(numberOfHistories) > 0;
     
     const { gtmChanged, doPublish } = stepsData;

     const [submitting, setSubmitting] = useState(false);

     const handlePublishChanges = () => {
          if (!isUnpublished) {
               closeWizard();
               return;
          }

          let contentSwitch = ''

          if(switchOptionEither) {
               contentSwitch = 'Switch to option Choose for Each Data Action'
          }

          if(isEither && sendTo === 'either') {
               contentSwitch = 'Switch to server side and client side'
          }

          if (activeAccount && versionPublish.version) {
               const values = {
                    name: contentSwitch ? contentSwitch : `Switch from ${(isGTM || (isEither && sendTo === 'gtm')) ? 'server side to client side' : 'client side to server side'}`,
                    accountId: activeAccount.id,
                    versionId: versionPublish.version.id,
               };
               setSubmitting(true);
               callTokenApi(API_VERSION_PUBLISH, 'POST', values)
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(setNumberOfHistories(0));
                              dispatch(setPublishingVersion(response.data.version));
                              dispatch(
                                   setVersionPublish({
                                        version: '',
                                        isPublish: 'publishing',
                                   })
                              );
                              toast.success(response.data.message);
                         } else {
                              toast.error(response.data.message);
                         }
                    })
                    .finally(() => {
                         setSubmitting(false);
                         setCurrentStep(SWITCH_TYPE_WIZARD_STEP.finally);
                    });
          } else {
               dispatch(setNumberOfHistories(0));
               toast.error('Latest version has already published');
          }
          dispatch(resetAllPreview(activeAccount.id));
     };

     return (
          <SwitchTypeWizardBody shouldHideBackBtn={true}>
               <CCard className='account-gtm success'>
                    <CCardBody>
                         <h3>Success!</h3>
                         {gtmChanged && (
                              <p className='des'>
                                   We've made those changes in GTM.{' '}
                                   {isGTM && !doPublish
                                        ? `You choose not to publish Google Tag Manager. We strongly recommend you go into GTM and review and publish the changes we have created in your workspace.`
                                        : ``}
                              </p>
                         )}
                         <p className='des'>
                              Last step: we'll publish your ListenLayer account changes. This will {isGTM || isEither ? 'finalize your changes.' : 'make Server Side live!'}
                         </p>
                         <CButton className='btn-save' type='submit' style={{ width: '40%' }} onClick={handlePublishChanges} disabled={submitting}>
                              <Waiting isLoading={submitting}>{isUnpublished ? 'PUBLISH CHANGES' : 'CLOSE'}</Waiting>
                         </CButton>
                    </CCardBody>
               </CCard>
          </SwitchTypeWizardBody>
     );
};

export default GTMSuccess;
