import React, { useEffect, useState } from 'react';
import { Message } from './Message.js';
import { CButton, CRow, CCol, CImg } from '@coreui/react';
import { callTokenApi } from '../../../../apiCaller.js';
import { useDispatch, useSelector } from 'react-redux';
import { setParentSetupGuideDataCompleted } from '../../../../actions/subscriber.js';
import BoxFeature from './BoxFeature.js';
import { API_CLIENT_ACCOUNT_COMPLETED_SETUP_GUIDE } from '../../../../constants/apiRest.js';
export const LearnAboutData = (props) => {
     const dispatch = useDispatch();
     const { nextTour, dataFeature, dataStepCompleted } = props;
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const [featureSuccess, setFeatureSuccess] = useState(false);

     const showAnimationRoadmap = localStorage.getItem('showAnimationRoadmap');

     const notShowStepAnimation = localStorage.getItem('dataEnrichment');

     useEffect(() => {
          setTimeout(() => {
               localStorage.setItem('dataEnrichment', true);
               localStorage.removeItem('showAnimationRoadmap');
          }, 2000);
     }, []); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          const numberStepCompleted = dataFeature.feature.filter((feature) => feature.status);
          if (numberStepCompleted.length === dataFeature.feature.length) {
               setFeatureSuccess(true);
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [dataFeature]);

     const onClickNextStep = () => {
          localStorage.setItem('dataEnrichment', true);
          localStorage.setItem('showAnimationRoadmap', true);
          nextTour();
          const data = {
               accountId: activeAccount.id,
               data: { dataEnrichment: true },
          };
          callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_SETUP_GUIDE, 'PUT', data).then((response) => {
               if (response.status === 200) {
                    dispatch(setParentSetupGuideDataCompleted({ dataEnrichment: true }));
               }
          });
     };

     const completeStep = dataStepCompleted ? (dataStepCompleted.completedSteps ? dataStepCompleted.completedSteps.dataEnrichment : false) : false;

     const isShowAnimation = !!(!notShowStepAnimation && !completeStep && showAnimationRoadmap);

     return (
          <CRow className='guide-row'>
               <CCol md='1'>
                    <CImg
                         src='/assets/images/avtAdSetupGuide.png'
                         height='60'
                         width='60'
                         className={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-1s' : ''}
                    />
               </CCol>
               <CCol md='11'>
                    <div className={`tour-guide show setup-guide`}>
                         <div className='tour-guide-content content'>
                              <Message
                                   text={"Let's take a moment to learn about data Enrichment."}
                                   hasLoading={isShowAnimation}
                                   animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-1s animate__faster-2s' : ''}
                                   animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-4s' : ''}
                              />
                              <Message
                                   text={
                                        'ListenLayer optionally allows you to identify real people and companies in your analytics data, and to enrich your data in other ways.'
                                   }
                                   hasLoading={isShowAnimation}
                                   animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-5s animate__faster-3s' : ''}
                                   animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-9s' : ''}
                              />
                              <Message
                                   text={'These steps should take less than 3 minutes to complete!'}
                                   hasLoading={isShowAnimation}
                                   animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-10s animate__faster-2s' : ''}
                                   animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-13s' : ''}
                              />
                              <div className={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-13s' : ''}>
                                   {dataFeature.feature.map((feature, index) => (
                                        <BoxFeature key={index} feature={feature} />
                                   ))}
                              </div>
                              {featureSuccess && (
                                   <>
                                        <Message
                                             text={"Nice work! Select NEXT when you're ready to move forward."}
                                             hasLoading={isShowAnimation}
                                             animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-14s' : ''}
                                             animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-15s' : ''}
                                        />
                                        <div
                                             className={`buttons justify-content-end ${
                                                  isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-16s' : ''
                                             }`}
                                        >
                                             <CButton className='next-button roadmap' onClick={() => onClickNextStep()}>
                                                  Next
                                             </CButton>
                                        </div>
                                   </>
                              )}
                         </div>
                    </div>
               </CCol>
          </CRow>
     );
};
