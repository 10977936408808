import React, { useContext } from 'react';
import { CButton, CCard, CCardBody } from '@coreui/react';
import { SwitchTypeWizardBody, SwitchTypeWizardContext } from '../SwitchTypeWizard';
import Waiting from '../../../../../general/Loadings/Waiting';
import { SWITCH_TYPE_WIZARD_STEP } from '../../../../../../constants';
import { useSelector, useDispatch } from 'react-redux';
import { setAccountDestination } from '../../../../../../actions/subscriber';

const MigrateSuccess = () => {
     const dispatch = useDispatch()
     const { setCurrentStep, isGTM, isEither, isServerSide, needConnectToGTM, destinationName, nothingObjectTurnOn } = useContext(SwitchTypeWizardContext);
     const accountDestination = useSelector((state) => state.subscriber.accountDestination);
     const switchTypeWizard = useSelector((state) => state.subscriber.switchTypeWizard);
     const {sendTo, switchOptionEither} = switchTypeWizard

     const goToNextStep = () => {
          if (switchOptionEither) {
               dispatch(setAccountDestination({...accountDestination, usingType: 'either' }));
               setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmSuccess);
          } else {
               if(isEither && sendTo) {
                    if(sendTo !== 'server') {
                         setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmSendObject);
                    } else {
                         setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmRemoveObject);
                    }
               } else {
                    if (needConnectToGTM) {
                         if (!isGTM && nothingObjectTurnOn) {
                              setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmSuccess);
                         } else {
                              setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmConnection);
                         }
                    } else {
                         setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmVerifyChanges);
                    }
               }
          }
     };

     const getContent = () => {
          let content = {}

          if(isGTM || (isEither && sendTo === 'gtm')) {
               content.heading = 'Client Side' 
               content.subHeading = `Before changes go into effect, they need to be sent to your Google Tag Manager account. We'll summarize the changes on the next screen.`
          }

          if(isServerSide || (isEither && sendTo === 'server')) {
               content.heading = 'Server Side' 
               content.subHeading = `We need to remove the client-side objects created inside GTM to support ${destinationName}. On the next screen, we will scan and return a list of objects so you can decide what to do.`
          }

          if(isEither && sendTo === 'either') {
               content.heading = 'Client Side and Server Side on each Data Action' 
               content.subHeading = `Before changes go into effect, they need to be sent to your Google Tag Manager account. We'll summarize the changes on the next screen.`  
          }

          if(!content.heading){
               content.heading = isGTM ? 'Client Side' : 'Server Side'
          }

          if(!content.subHeading){
               if(isGTM) {
                    content.subHeading = `Before changes go into effect, they need to be sent to your Google Tag Manager account. We'll summarize the changes on the next screen.`
               } else {
                    content.subHeading = `We need to remove the client-side objects created inside GTM to support ${destinationName}. On the next screen, we will scan and return a list of objects so you can decide what to do.`
               }
          }

          return content
     }

     const contentMigrateSuccess = getContent()

     return (
          <SwitchTypeWizardBody shouldHideBackBtn={true}>
               <CCard className="migrate-success">
                    <CCardBody>
                         <h3>Success!</h3>
                         {switchOptionEither ? (
                              <p>At present, you have the flexibility to select either Server Side tagging or Client side tagging for any Data Action you desire.</p>
                         ) : (
                              <>
                                   <p className="des">
                                        Your {destinationName} destination is ready to run {contentMigrateSuccess.heading}!
                                   </p>
                                   <p className="des">
                                        {contentMigrateSuccess.subHeading}
                                   </p>
                              </>
                         )}

                         <CButton className="btn-save" type="submit" style={{ width: '40%' }} onClick={goToNextStep}>
                              <Waiting>NEXT</Waiting>
                         </CButton>
                    </CCardBody>
               </CCard>
          </SwitchTypeWizardBody>
     );
};

export default MigrateSuccess;
