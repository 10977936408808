import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { fetchDimensionsRequest, fetchScopesRequest, setDateRangeReports } from '../../../../actions/subscriber';
import { callTokenApi } from '../../../../apiCaller';
import { API_GET_ECO_ITEM_CATEGORIES, CLIENT_GET_SAVED_REPORT_BY_ID, CLIENT_GET_SAVED_VIEWS, DEFAULT_DATE_FORMAT, FILTER_DATA_TYPES, REPORT_DATA_TYPES, REPORT_NAMES } from '../../../../constants';
import {
     DATE_SCOPE,
     PERIOD_TYPES,
     REPORT_TAB_TYPES,
     SCOPED,
     // TYPE_PROPERTIES_CUSTOM_FILTER_NEED_REMOVE,
     VARIABLE_OPERATORS_REPORT,
     WEIGHT_TYPES,
} from '../../../../constants/analytics';
import { camelCase, decodeObj } from '../../../../utils';
import CenterSpinner from '../../../general/Loadings/CenterSpinner';
import ChartReport from './ChartReport';
import CustomDatePicker from './CustomDatePicker';
import MenuReport from './MenuReport';
import ReportTableV2 from './ReportTable-V2';
import ScopeDropdown from './ScopeDropdown';
import useConversionName from './useConversionName';
import useUtmArray from './useUtmArray';

export const EventExplorerContext = createContext();
export const handleGetOption = ({ option, type = '', filter = [], reportName, defaultArr, weight, menuFilter, isDefaultMetrics = false }) => {
     let newOptions = [];
     let optionReport = option;
     const hasDefaultArrReports = [
          REPORT_NAMES.UTM_REPORT,
          REPORT_NAMES.ALL_PAGE_REPORT,
          REPORT_NAMES.LANDING_PAGE_REPORT,
          REPORT_NAMES.EXIT_PAGE_REPORT,
          REPORT_NAMES.BY_LANDING_PAGE_REPORT,
          REPORT_NAMES.BY_UTM_REPORT,
          REPORT_NAMES.FORM_CATEGORY_REPORT,
          REPORT_NAMES.SOURCE_REPORT,
          REPORT_NAMES.BY_SOURCE_REPORT,
          REPORT_NAMES.CHANNEL_REPORT,
          REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT,
          REPORT_NAMES.ECOMMERCE_ITEMS,
     ];

     if (isDefaultMetrics && type === 'defaultMetric') {
          optionReport = option.filter((_item) => _item.isDefault);
     }

     if (hasDefaultArrReports.includes(reportName) && type === 'defaultDimension') {
          optionReport = defaultArr;
     }
     // add select all
     if (type === 'option' && menuFilter !== 'filterDimension') {
          newOptions.push({ label: 'Select All', value: '' });
     }
     optionReport.forEach((item) => {
          // report conversion source attribution case 1: not select weights default case 2: weights select when model (weight) have select
          if (
               reportName === REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT &&
               (!WEIGHT_TYPES.find((el) => el.key === item.key) || (weight && weight.value && item.key === weight.value))
          ) {
               if (type === 'weight') {
                    newOptions.push({ label: item.label, key: item.key });
               } else {
                    newOptions.push({ label: item.label, value: item.key });
               }
          } else if (reportName !== REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT) {
               newOptions.push({ label: item.label, value: item.key });
          }
     });
     // select all item show no options
     if (filter.length > 0 && newOptions.some((item) => item.label === 'Select All') && filter.length === newOptions.length - 1) {
          newOptions = [];
     }
     return newOptions;
};

const useDataParam = () => {
     const location = useLocation();
     let dataParam = new URLSearchParams(location.search).get('data');
     let dataParamValue;

     if (dataParam) {
          dataParamValue = decodeObj(dataParam);
     }

     return dataParamValue;
};

const getPropertiesByCustomDimension = ({ dimensions, properties, customDimensionsTemp, reportName, scoped, scopes }) => {
     // DL-1786

     let newProperties = [];
     const customProperties = [];
     // array need check custom properties, includes: dimensions, properties
     const arrayNeedCheck = [...dimensions, ...properties];
     const sameNamePropertyArray = [];

     customDimensionsTemp.forEach((item) => {
          const scopeItem = [];
          let target = '';
          let isSameNameProperty = false;
          const removeItem = ['ads_storage', 'Regional Consent Rule'].includes(item.name) && scoped === SCOPED.EVENT;
          const sameProperty = arrayNeedCheck.find((el) => item.isCustom === false && el.label === item.name); // the same label
          const sameKeyProperty = arrayNeedCheck.find((el) => item.isCustom === false && el.key === camelCase(item.name)); // the same path
          const sameNameProperty = customDimensionsTemp.find(
               (el) => item.isCustom === false && el.name !== item.name && camelCase(el.name) === camelCase(item.name)
          ); // the same path

          item.availableScopes.forEach((el) => {
               let scope = scopes.find((e) => e.id === el.id);
               if (scope) {
                    scopeItem.push(scope.name);
               }

               if (scope && scope.name.includes(scoped)) {
                    target = el.target;
               }
          });
          if (scopeItem.find((e) => e.includes(scoped)) && sameNameProperty) {
               sameNamePropertyArray.push(item);
          }
          if (sameNamePropertyArray.length > 1) {
               // name custom report dup or many dup
               const searchTerm = camelCase(item.name);
               const searchRegex = new RegExp(searchTerm, 'i');
               const occurrences = sameNamePropertyArray.filter((obj) =>
                    Object.values(obj).some((value) => typeof value === 'string' && value.match(searchRegex))
               );
               const countOccurrences = occurrences.length || 0;
               if (countOccurrences > 1) {
                    isSameNameProperty = true;
               }
          }

          const isWebConversion = item.availableScopes.some((item) => item.isWebConversion);

          const addCustomProperty = scopeItem.find((e) => e.includes(scoped)) && !sameProperty && !sameKeyProperty && !isSameNameProperty && !removeItem;
          if (
               (reportName !== REPORT_NAMES.CONVERSION_EVENTS && addCustomProperty) || (reportName === REPORT_NAMES.CONVERSION_EVENTS && isWebConversion)
          ) {
               newProperties.push({
                    key: `${camelCase(
                         item.name
                              .replace(/\b\w/g, (match) => match.toUpperCase())
                              .replace(/^\w/, (match) => match.toLowerCase())
                              .replace(/\s+/g, '')
                    )}Custom`,
                    label: item.name,
               });
               customProperties.push({
                    ...item,
                    properties: `${camelCase(
                         item.name
                              .replace(/\b\w/g, (match) => match.toUpperCase())
                              .replace(/^\w/, (match) => match.toLowerCase())
                              .replace(/\s+/g, '')
                    )}Custom`,
                    target: target,
               });
          }
     });
     if (newProperties.length > 0) {
          newProperties = newProperties.sort((a, b) => a.key.localeCompare(b.key));
     }
     if (
          newProperties.length > 0 &&
          [
               REPORT_NAMES.SESSION_DETAILS,
               REPORT_NAMES.USER_DETAILS,
               REPORT_NAMES.PERSON_DETAILS,
               REPORT_NAMES.COMPANY_DETAILS,
               REPORT_NAMES.PERSON_DETAILS_PROFILE,
               REPORT_NAMES.EVENT_DETAILS,
               REPORT_NAMES.CONVERSION_EVENTS,
          ].includes(reportName)
     ) {
          return { newProperties: properties.concat(newProperties), customProperties: customProperties };
     } else {
          return { newProperties: properties, customProperties: [] };
     }
};
const EventExplorer = ({ title, reportName, reportType, recordProfile, filterRecord }) => {
     const dispatch = useDispatch();
     const dataParamValue = useDataParam();
     const location = useLocation();
     const reportDetailData = REPORT_DATA_TYPES[reportName];
     const isChangeScope = useRef(false);
     const [scoped, setScoped] = useState(
          reportName === 'sessionDurationReport' || reportName === 'conversionCountReport'
               ? Object.keys(REPORT_DATA_TYPES[reportName])[1]
               : Object.keys(REPORT_DATA_TYPES[reportName])[0]
     );
     const dateScopes =
          (reportDetailData && (reportDetailData.dateScopes || (reportDetailData[scoped] && reportDetailData[scoped].dateScopes))) || [];
     const dateScopeOptions = dateScopes.map((item) => ({ label: item, value: item }));
     const initDateScope =
          (dataParamValue && dataParamValue.dateScope) ||
          (dateScopes.includes(DATE_SCOPE.CREATION_DATE) ? DATE_SCOPE.CREATION_DATE : DATE_SCOPE.EVENT_ACTIVITY);
     let {
          dimensions,
          properties,
          metrics,
          defaultDimensions = [],
          defaultWeight,
          isDefaultMetrics,
     } = reportType ? REPORT_DATA_TYPES[reportName][scoped] : REPORT_DATA_TYPES[reportName];
     const {
          isConversionName,
          isConversionNameRate,
          isConversionNameValue = false,
     } = reportType ? REPORT_DATA_TYPES[reportName][scoped] : REPORT_DATA_TYPES[reportName];
     const [conversionNameMetric, setConversionNameMetric] = useState([]);
     const [conversionName, setConversionName] = useState([]);
     const [utmArray, setUtmArray] = useState([]);
     const [eCoItemCategories, setECoItemCategories] = useState([]);
     const user = useSelector((state) => state.theme.user);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const dateRangeReports = useSelector((state) => state.subscriber.dateRangeReports);
     const customDimensions = useSelector((state) => state.subscriber.customReport.dimensions);
     const scopes = useSelector((state) => state.subscriber.customReport.scopes);
     const dayjsNow = dayjs().tz(activeAccount.timeZone);
     let defaultDateRange = {
          selection: {
               startDate: dayjsNow.subtract(1, 'day'),
               endDate: dayjsNow.subtract(0, 'day'),
          },
     };
     if (isEmpty(dateRangeReports)) {
          dispatch(setDateRangeReports(defaultDateRange));
     }
     useUtmArray({
          utmArray,
          setUtmArray,
          dimensions,
          reportName,
          dateRangeReports,
     });
     const accountId = activeAccount.id;
     const customDimensionsTemp = customDimensions.filter((item) => item.status);
     const { newProperties, customProperties } = getPropertiesByCustomDimension({
          dimensions,
          properties,
          customDimensionsTemp,
          reportName,
          scoped,
          scopes,
     });
     // const maxStepForm = useSelector((state) => state.subscriber.maxStepForm);
     const [tableData, setTableData] = useState([]);
     const [metricsStepForm, setMetricsStepForm] = useState([]);
     const [filterMetric, setFilterMetric] = useState(handleGetOption({ option: metrics, isDefaultMetrics, type: 'defaultMetric' }));
     const [filterDimensions, setFilterDimensions] = useState(
          handleGetOption({ option: dimensions, type: 'defaultDimension', reportName: reportName, defaultArr: defaultDimensions })
     );
     const [maxStepForm, setMaxStepForm] = useState(0);
     const [filterProperties, setFilterProperties] = useState(handleGetOption({ option: properties.filter((_p) => _p.isDefault) }));
     const [sort, setSort] = useState(
          filterMetric[0] && reportName !== REPORT_NAMES.ECOMMERCE_PURCHASE_FLOW ? { fieldName: filterMetric[0].value, isDesc: true } : {}
     );
     const [itemsPerPage, setItemsPerPage] = useState(20);
     const [isFilterConversion, setIsFilterConversion] = useState(false);
     const [offSet, setOffSet] = useState(0);
     const [fetchNextPage, setFetchNextPage] = useState(false);
     const [showChart, setShowChart] = useState(false);
     const [changeData, setChangeData] = useState(false);
     //
     const [metricDefault, setMetricDefault] = useState([]);
     const [convRate, setConvRate] = useState([]);
     const [convName, setConvName] = useState([]);
     const [weight, setWeight] = useState(defaultWeight);
     const [isDirect, setIsDirect] = useState(false);
     const [eCommerce, setECommerce] = useState([]);
     const [attributeBeforeReload, setAttributeBeforeReload] = useState({ dimensions: [], properties: [], metrics: [] });
     const [isRemoveCustomPropertyHaveFilter, setIsRemoveCustomPropertyHaveFilter] = useState(false);
     const { enableFullAnalytics, isRevealedCompanies, behavioralAnalytics } = activeAccount;
     const showData = localStorage.getItem('llShowData') || window.location.search.includes('show-data=1') || behavioralAnalytics;
     const enableRevealedCompanies =
          activeAccount.enableRevealedCompanies ||
          isRevealedCompanies ||
          localStorage.getItem('llRevealedCompany') === '1' ||
          window.location.search.includes('revealed-company=1');
     const convertToVariableSelect = () => {
          const newArr = [];
          if (REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT === reportName || REPORT_NAMES.ECOMMERCE_ITEMS === reportName) {
               [...dimensions, ...utmArray, ...eCoItemCategories].forEach((e) => {
                    const filterDimension = filterDimensions.find((item) => item.value === e.key);
                    if (filterDimension) {
                         newArr.push({ value: e.key, label: e.label });
                    }
               });
          } else {
               dimensions.forEach((e) => {
                    if (reportName !== REPORT_NAMES.BY_LANDING_PAGE_REPORT) {
                         newArr.push({ value: e.key, label: e.label });
                    } else if (e.key !== 'pathName') {
                         newArr.push({ value: e.key, label: e.label });
                    }
               });
          }
          newProperties.forEach((e) => {
               // const findCustom = customProperties.find((item) => item.properties === e.key && item.isCustom === false);
               if (customProperties.find((item) => item.properties === e.key)) {
                    // const findCustomDefault = TYPE_PROPERTIES_CUSTOM_FILTER_NEED_REMOVE.find(
                    //      (el) => findCustom && el.value === findCustom.properties
                    // );
                    // if (!findCustomDefault) {
                    newArr.push({ value: e.key, label: `${e.label}` });
                    // }
               } else {
                    newArr.push({ value: e.key, label: e.label });
               }
          });
          filterMetric.forEach((e) => {
               if (
                    reportName !== REPORT_NAMES.EVENT_DETAILS &&
                    reportName !== REPORT_NAMES.PAGE_VIEW_DETAILS &&
                    reportName !== REPORT_NAMES.FORM_SUBMISSION_EVENTS
               ) {
                    newArr.push({ value: e.value, label: e.label });
               }
          });
          FILTER_DATA_TYPES[reportName].forEach((e) => {
               if (e.type) {
                    newArr.push({ value: e.type, label: e.label });
               }
          });
          if (dataParamValue && Array.isArray(dataParamValue.filters) && reportName === REPORT_NAMES.SESSION_DETAILS) {
               let conversionSource = REPORT_DATA_TYPES[REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT][SCOPED.CONVERSION];
               const array = [];
               utmArray.forEach((e) => {
                    const dimension = conversionSource.dimensions.find((item) => item.label === e.key);
                    if (!dimension) {
                         array.push({ value: e.key, label: e.label });
                    }
               });
               const findLinkUtm = dataParamValue.filters.find((el) => array.some((e) => e.value === el.type));
               if (findLinkUtm) {
                    newArr.push({ value: findLinkUtm.type, label: findLinkUtm.type });
               }
          }
          return newArr;
     };
     // const filterOptionDefaults = convertToVariableSelect();
     const [filterOptions, setFilterOptions] = useState(convertToVariableSelect());
     useEffect(() => {
          setFilterOptions(convertToVariableSelect());
     }, [filterProperties, filterMetric, filterDimensions]); //eslint-disable-line
     useEffect(() => {
          if (reportName === REPORT_NAMES.ECOMMERCE_ITEMS) {
               callTokenApi(API_GET_ECO_ITEM_CATEGORIES, 'POST', { accountId }).then((response) => {
                    if (response && response.status === 200) {
                         const itemCategories = response.data.itemCategories;
                         setECoItemCategories(itemCategories);
                    } else {
                         setECoItemCategories([]);
                    }
               });
          }
     }, []); //eslint-disable-line
     const conversionNameLoading = useConversionName({
          isConversionNameRate,
          isConversionName,
          isConversionNameValue,
          metrics,
          setConversionNameMetric,
          setConversionName,
          reportName,
     });

     const [firstChangeData, setFirstChangeData] = useState(false); //eslint-disable-line
     const [filterEvent, setFilterEvent] = useState(() => {
          let filters = [];
          if (dataParamValue && Array.isArray(dataParamValue.filters)) {
               dataParamValue.filters.forEach((el) => {
                    const foundIndex = filterOptions.findIndex((filterOption) => filterOption.value === el.type);
                    const foundUtm = el.utmArray && el.utmArray.findIndex((utm) => utm.key === el.type);
                    if (foundIndex > -1 || foundUtm >= 0) {
                         filters.push(el);
                    }
               });
               if (filters.length > 0) {
                    setUtmArray([
                         ...filters.map((item) => {
                              return { key: item.type, label: item.type };
                         }),
                    ]);
               }
          } else {
               filters = [{ type: '', value: '', id: '', operator: 'ct', optionsOperator: VARIABLE_OPERATORS_REPORT }];
          }
          return filters;
     });
     // useEffect(() => {
     //      if (metricsStepForm.length > 0) {
     //           setFilterOptions(() => [
     //                ...filterOptionDefaults,
     //                ...metricsStepForm.map((item) => {
     //                     return { value: item.key, label: item.label };
     //                }),
     //           ]);
     //      }
     // }, [metricsStepForm]); //eslint-disable-line
     useEffect(() => {
          setFilterOptions(convertToVariableSelect());
     }, [filterProperties, scoped]); //eslint-disable-line
     // useEffect(() => {
     //      if(REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT === reportName){
     //           setFilterOptions(convertToVariableSelect());
     //      }
     // }, [filterDimensions]); //eslint-disable-line
     useEffect(() => {
          if (recordProfile) {
               setFilterEvent(filterRecord);
               setItemsPerPage(10);
          }
     }, [recordProfile]); // eslint-disable-line react-hooks/exhaustive-deps

     const [reportData, setReportData] = useState({
          overallRowCount: 0,
          rows: [],
          event: [],
          totalsRow: [],
     });
     const [reportCompare, setReportCompare] = useState({ rows: undefined, totalsRow: undefined });
     const [filterEventReport, setFilterEventReport] = useState(false);
     const [triggerClear, setTriggerClear] = useState(false);
     const [isLoading, setIsLoading] = useState(true);
     const [viewLoading, setViewLoading] = useState(false);
     const [dateRangeConfig, setDateRangeConfig] = useState({
          comparePeriod: false,
          periodType: PERIOD_TYPES.PREVIOUS_PERIOD,
          datePreset: null,
          dateScope: initDateScope,
     });
     const [tempReport, setTempReport] = useState({ temp: ``, tempCompare: `` });
     useEffect(() => {
          setDateRangeConfig((prev) => ({ ...prev, dateScope: initDateScope }));
     }, [initDateScope]); // eslint-disable-line react-hooks/exhaustive-deps

     if (dataParamValue && dataParamValue.dateRange) {
          defaultDateRange.selection = {
               startDate: dayjs(dataParamValue.dateRange.startDate),
               endDate: dayjs(dataParamValue.dateRange.endDate),
          };
     }
     if (dataParamValue && dataParamValue.isDateRange) {
          defaultDateRange.selection = {
               startDate: dayjsNow.subtract(14, 'day'),
               endDate: dayjsNow.subtract(0, 'day'),
          };
     }

     // const [dateRange, setDateRange] = useState(defaultDateRange);

     const handleSetFilterDimensions = (newDimensions) => {
          // if (newDimensions.length === 0) {
          //      toast.error(`Can't remove all dimensions`);
          //      return;
          // }

          // if (newDimensions.length && newDimensions[0].value === 'formHostNamePathName') {
          //      toast.error(`Can't remove FormID dimensions`);
          //      return;
          // }
          setFilterDimensions(newDimensions);
     };

     const fetchDimensions = () => {
          dispatch(fetchDimensionsRequest(accountId));
     };

     const fetchScopes = () => {
          dispatch(fetchScopesRequest(accountId));
     };
     useEffect(() => {
          fetchDimensions();
          fetchScopes();
     }, [accountId]); // eslint-disable-line react-hooks/exhaustive-deps

     // const filterMetricSelection = (arr1, arr2) => {
     //      const result = arr1.filter((item1) => {
     //           const valueStartsWithStep = item1.value.startsWith('step');
     //           const existsInObject2 = arr2.some((item2) => item1.value === item2.value);
     //           return !(valueStartsWithStep && !existsInObject2);
     //      });
     //      return result;
     // };

     // const mergeArraysDynamic = (arr1, arr2) => {
     //      //eslint-disable-line
     //      // const existingSteps = new Set(
     //      //      arr1.filter((item) => item.value.startsWith('step')).map((item) => item.label.split(' ')[0] + item.label.split(' ')[1])
     //      // );

     //      // const newSteps = arr2.filter(
     //      //      (item) => item.value.startsWith('step') && !existingSteps.has(item.label.split(' ')[0] + item.label.split(' ')[1])
     //      // );

     //      const mergedArray = [...arr1];

     //      const stepArr2 = new Set(
     //           arr2.filter((item) => item.value.startsWith('step')).map((item) => item.label.split(' ')[0] + item.label.split(' ')[1])
     //      );

     //      const result = mergedArray.filter(
     //           (item) => !item.value.startsWith('step') || stepArr2.has(item.label.split(' ')[0] + item.label.split(' ')[1])
     //      );

     //      return result;
     // };

     // useEffect(() => {
     // if (reportName === REPORT_NAMES.ECOMMERCE_PURCHASE_ACTIVITY) {
     //      setFilterMetric((prev) => prev.filter(item => item.value !== 'companyCount'));
     // }

     // if (reportName === REPORT_NAMES.FORM_CATEGORY_REPORT && firstChangeData) {
     //      setFilterMetric((prev) => mergeArraysDynamic(prev, handleGetOption({ option: [...metricsStepForm] })));
     // }
     // }, [tableData]); //eslint-disable-line

     useEffect(() => {
          if (reportName === REPORT_NAMES.ECOMMERCE_PURCHASE_TRANSACTION) {
               const newProperties = properties.filter((_item) => !['userSourceFirst', 'userSourceLast'].includes(_item.key));
               setFilterProperties(handleGetOption({ option: newProperties }));
          }
     }, []); //eslint-disable-line

     useEffect(() => {
          setIsFilterConversion(false);
     }, [scoped]);

     useEffect(() => {
          if (filterProperties.length > 0 && filterOptions.length > 0) {
               let newOptions = [...filterEvent];
               let change = false;
               filterEvent.forEach((item) => {
                    const checkSelectCustomPropeties = filterProperties.find((el) => el.value === item.type); // select custom properties
                    const checkFilterCustomPropeties = customProperties.find((el) => el.properties === item.type); // select filter custom propeties
                    if (!checkSelectCustomPropeties && checkFilterCustomPropeties) {
                         newOptions = newOptions.filter((el) => el.type !== item.type);
                         change = true;
                    }
               });
               if (change) {
                    if (newOptions.length === 0) {
                         newOptions.push({
                              type: '',
                              value: '',
                              operator: 'ct',
                              optionsOperator: VARIABLE_OPERATORS_REPORT,
                         });
                    }
                    setFilterEvent(newOptions);
                    setIsRemoveCustomPropertyHaveFilter(change);
               }
          }
     }, [filterProperties]); // eslint-disable-line react-hooks/exhaustive-deps

     const newDateRange = {
          startDate: dateRangeReports.selection && dateRangeReports.selection.startDate.format(DEFAULT_DATE_FORMAT),
          endDate: dateRangeReports.selection && dateRangeReports.selection.endDate.format(DEFAULT_DATE_FORMAT),
     };

     //report view

     const [listView, setListView] = useState([]);
     const [listViewByScoped, setListViewByScoped] = useState([]);
     const [viewDefault, setViewDefault] = useState({});
     const [reportView, setReportView] = useState({});
     const [isMapView, setIsMapView] = useState(true);
     const [isHandleParams, setIsHandleParams] = useState(true);
     const [isApplyMetric, setIsApplyMetric] = useState(true);
     const reportViewParam = new URLSearchParams(location.search).get('reportView');

     // fetch list saved views
     const fetchSavedViews = () => {
          setViewLoading(true);

          const query = `?accountId=${activeAccount.accountId}&userId=${user.id}&reportName=${reportName}`;

          callTokenApi(`${CLIENT_GET_SAVED_VIEWS}/${query}`, 'GET').then((response) => {
               if (response.status === 200) {
                    const reportViews = response.data.reportViews;
                    setViewLoading(false);

                    const isFilter = !dataParamValue;
                    const findViewDefault = reportViews.find(_item => _item.isDefault) || reportViews.find(_item => _item.isSystem);
                    if (findViewDefault) {
                         setViewDefault(findViewDefault);
                         setFilterViewDefault(findViewDefault, isFilter);
                         if(findViewDefault.isSystem){
                              const newReportViews = reportViews.map((_view) => {
                                   if (_view.id === findViewDefault.id) {
                                        _view.isDefault = true;
                                   }
                                   return _view;
                              });
                              setListView(newReportViews);
                         } else {
                              setListView(reportViews);
                         }
                    }

                    if (dataParamValue) {
                         const contentFilter = dataParamValue.filters.length
                              ? dataParamValue.filters.map((_param) => ({
                                   type: _param.type,
                                   value: _param.value,
                                   id: '',
                                   operator: _param.operator,
                                   optionsOperator: _param.optionsOperator,
                              }))
                              : [];

                         for (const _item of reportViews) {
                              const itemFilter = [];

                              for (const _filter of _item.content.filter) {
                                   itemFilter.push({
                                        type: _filter.type,
                                        value: _filter.value,
                                        id: '',
                                        operator: _filter.operator,
                                        optionsOperator: _filter.optionsOperator,
                                   });
                              }

                              const content = JSON.stringify(contentFilter);
                              const itemContent = JSON.stringify(itemFilter);

                              if (content === itemContent) {
                                   setFilterViewDefault(_item);
                                   setIsMapView(true);

                                   break;
                              } else {
                                   setIsMapView(false);
                              }
                         }
                    }
               }
          });
     };

     //get list view by scoped
     useEffect(() => {
          if (listView.length > 0) {
               const newListViewByScoped = listView.filter((_view) => _view.scoped === scoped);
               setListViewByScoped(newListViewByScoped);
          }
     }, [listView]); // eslint-disable-line

     //get list view by scoped
     useEffect(() => {
          if (listView.length > 0) {
               const newListViewByScoped = listView.filter((_view) => _view.scoped === scoped);
               setListViewByScoped(newListViewByScoped);
               
               const findViewDefault = newListViewByScoped.find(_item => _item.isDefault) || newListViewByScoped.find(_item => _item.isSystem);
               if (findViewDefault) {
                    setViewDefault(findViewDefault);
                    setFilterViewDefault(findViewDefault);
               }
          }

     }, [scoped]); // eslint-disable-line

     const getReportValue = (scoped) => {
          let { dimensions, properties, metrics } = reportType ? REPORT_DATA_TYPES[reportName][scoped] : REPORT_DATA_TYPES[reportName];

          const { newProperties } = getPropertiesByCustomDimension({
               dimensions,
               properties,
               customDimensionsTemp,
               reportName,
               scoped,
               scopes,
          });

          if (reportName === REPORT_NAMES.ECOMMERCE_ITEMS) {
               dimensions = [...dimensions, ...eCoItemCategories];
          }

          dimensions = handleGetOption({ option: dimensions, reportName });
          properties = handleGetOption({ option: newProperties, type: 'option', filter: [] });
          metrics = handleGetOption({ option: [...metrics, ...conversionNameMetric, ...metricsStepForm] });

          return {
               dimensions,
               properties,
               metrics,
          };
     };

     const sortAndFilterArray = (referenceArray, targetArray) => {
          targetArray.sort((a, b) => {
               const indexA = referenceArray.indexOf(a.value);
               const indexB = referenceArray.indexOf(b.value);

               if (indexA === -1 && indexB === -1) return 0;
               if (indexA === -1) return 1;
               if (indexB === -1) return -1;

               return indexA - indexB;
          });
          return targetArray.filter((item) => referenceArray.includes(item.value));
     };

     //set (dimensions, properties, metric, filter) with view
     const setFilterViewDefault = (viewDefault, isFilter = true, isApplyMetric = true, isFetchReport = true) => {
          if (Object.keys(viewDefault).length > 0) {
               setViewDefault(viewDefault);
               const content = viewDefault.content;
               const reportValue = getReportValue(viewDefault.scoped);

               setFilterDimensions(sortAndFilterArray(content.dimensions, reportValue.dimensions));
               setFilterProperties(sortAndFilterArray(content.properties, reportValue.properties));
               if (isApplyMetric) {
                    setFilterMetric(sortAndFilterArray(content.metrics, reportValue.metrics));
               }

               if (content.weight) {
                    setIsDirect(content.weight.noneDirect);
                    setWeight(content.weight);
               }

               if (isFilter && !recordProfile) {
                    setFilterEvent(content.filter);
               }

               if (isFetchReport) {
                    setFilterEventReport(!filterEventReport);
               }
               setScoped(viewDefault.scoped);
               setIsMapView(true);
          }
     };

     //handle with param reportView
     const handleParamsReportView = (reportView) => {
          if (Object.keys(reportView).length > 0) {
               const viewContent = reportView.content;
               const dateRanges = viewContent.dateRanges;
               if (dateRanges) {
                    if (dateRanges.compare) {
                         setItemsPerPage(7);
                    }
                    const newDateRange = {
                         ...(dateRanges.compare && {
                              compare: {
                                   startDate: dayjs(dateRanges.compare.startDate),
                                   endDate: dateRanges.compare.endDate ? dayjs(dateRanges.compare.endDate) : dayjs(dateRanges.compare.startDate),
                              },
                         }),
                         selection: {
                              startDate: dayjs(dateRanges.startDate),
                              endDate: dateRanges.endDate ? dayjs(dateRanges.endDate) : dayjs(dateRanges.startDate),
                         },
                    };
                    dispatch(setDateRangeReports(newDateRange));
                    setDateRangeConfig((prev) => ({ ...prev, dateScope: dateRanges.dateScope, comparePeriod: !!dateRanges.compare }));
               }

               const reportValue = getReportValue(reportView.scoped);

               const dimensionValue = sortAndFilterArray(viewContent.dimensions, reportValue.dimensions);
               const propertyValue = sortAndFilterArray(viewContent.properties, reportValue.properties);
               const metricValue = sortAndFilterArray(viewContent.metrics, reportValue.metrics);
               const payload = {
                    filter: viewContent.filter,
                    metrics: metricValue.length ? metricValue.map((_item) => _item.value) : [],
                    dimensions: dimensionValue.length ? dimensionValue.map((_item) => _item.value) : [],
                    properties: propertyValue.length ? propertyValue.map((_item) => _item.value) : [],
                    ...(viewContent.weight && { weight: viewContent.weight }),
               };

               const content = JSON.stringify(payload);

               const isViewMatched = listViewByScoped.some((view) => {
                    const newContent = view.content;
                    const itemContent = JSON.stringify(newContent);
                    if (content === itemContent) {
                         setFilterViewDefault(view);
                         return true;
                    }
                    return false;
               });

               if (isViewMatched) {
                    setIsMapView(true);
               } else {
                    setScoped(reportView.scoped);
                    if (!recordProfile) {
                         setFilterEvent(viewContent.filter);
                    }
                    if (viewContent.weight) {
                         setIsDirect(viewContent.weight.noneDirect);
                         setWeight(viewContent.weight);
                    }
                    setFilterDimensions(reportValue.dimensions.filter((dim) => viewContent.dimensions.includes(dim.value)));
                    setFilterProperties(reportValue.properties.filter((prop) => viewContent.properties.includes(prop.value)));
                    setFilterMetric(reportValue.metrics.filter((metric) => viewContent.metrics.includes(metric.value)));
                    setIsMapView(false);
               }
          }
     };

     //fetch view default
     const fetchReportViewById = () => {
          const parseParams = decodeObj(reportViewParam);
          const idViewReport = parseParams.id;
          const query = `?id=${idViewReport}`;

          callTokenApi(`${CLIENT_GET_SAVED_REPORT_BY_ID}/${query}`, 'GET').then((response) => {
               if (response.status === 200) {
                    const reportView = response.data.reportViews;
                    if (reportView) {
                         setReportView(reportView);
                         handleParamsReportView(reportView);
                    }
               }
          });
     };

     //handle find view when change element(dimensions, properties, metric, filter)
     const handleFindView = (type, values, isFetchReport = true) => {
          let dimensions = filterDimensions;
          let filter = filterEvent;
          let metrics = filterMetric;
          let properties = filterProperties;
          let weightData = {
               ...weight,
               noneDirect: isDirect,
          };

          if (type === REPORT_TAB_TYPES.FILTER) {
               filter = values;
          }

          if (type === REPORT_TAB_TYPES.DIMENSIONS) {
               if (!values.length) {
                    dimensions = [values];
               } else {
                    dimensions = values;
               }
          }

          if (type === REPORT_TAB_TYPES.METRICS) {
               metrics = values;
          }

          if (type === REPORT_TAB_TYPES.PROPERTIES) {
               properties = values;
          }

          if (type === REPORT_TAB_TYPES.MODEL) {
               weightData = {
                    ...values,
               };
          }

          const payload = {
               filter: filter,
               metrics: metrics.length ? metrics.map((_item) => _item.value) : [],
               dimensions: dimensions.length ? dimensions.map((_item) => _item.value) : [],
               properties: properties.length ? properties.map((_item) => _item.value) : [],
               ...(weight && { weight: weightData }),
          };

          const content = JSON.stringify(payload);
          for (const _item of listViewByScoped) {
               const itemContent = JSON.stringify(_item.content);
               if (content === itemContent) {
                    setFilterViewDefault(_item, true, true, isFetchReport);
                    break;
               } else {
                    setIsMapView(false);
               }
          }
     };

     useEffect(() => {
          if (!recordProfile) {
               if (Object.keys(reportView).length === 0  && reportViewParam) {
                    fetchReportViewById();
               }

               if (!listView.length) {
                    fetchSavedViews();
               }
          }
     }, []); // eslint-disable-line

     //update custom dimensions, properties, metric

     useEffect(() => {
          if (!recordProfile && listViewByScoped.length > 0) {
               if (reportViewParam) {
                    if (Object.keys(reportView).length && isHandleParams) {
                         handleParamsReportView(reportView);
                    }
               } else {
                    if (Object.keys(viewDefault).length > 0 && isMapView) {
                         const content = viewDefault.content;
                         const reportValue = getReportValue(viewDefault.scoped);
                         setFilterDimensions(sortAndFilterArray(content.dimensions, reportValue.dimensions));
                         setFilterProperties(sortAndFilterArray(content.properties, reportValue.properties));
                         if (isApplyMetric) {
                              setFilterMetric(sortAndFilterArray(content.metrics, reportValue.metrics));
                         }
                         }
                    }
          }
      }, [eCoItemCategories, scopes, customDimensions, metricsStepForm, listViewByScoped, reportView]); // eslint-disable-line
     
     const contextValue = {
          reportName,
          filterMetric,
          setFilterMetric,
          handleGetOption,
          filterDimensions,
          setFilterDimensions: handleSetFilterDimensions,
          filterProperties,
          setFilterProperties,
          filterEvent,
          setFilterEvent,
          setReportData,
          reportData,
          setFilterEventReport,
          filterEventReport,
          isLoading,
          setIsLoading,
          dateRangeConfig,
          setDateRangeConfig,
          filterOptions,
          tableData,
          setTableData,
          sort,
          setSort,
          itemsPerPage,
          setItemsPerPage,
          offSet,
          setOffSet,
          scoped,
          setScoped,
          reportType,
          showChart,
          setShowChart,
          conversionNameMetric,
          conversionName,
          dateScopeOptions,
          isFilterConversion,
          setIsFilterConversion,
          reportCompare,
          setReportCompare,
          fetchNextPage,
          setFetchNextPage,
          showData,
          enableFullAnalytics,
          enableRevealedCompanies,
          changeData,
          setChangeData,
          recordProfile,
          newProperties,
          customProperties,
          metricsStepForm,
          setMetricsStepForm,
          metricDefault,
          setMetricDefault,
          convRate,
          setConvRate,
          convName,
          setConvName,
          eCommerce,
          setECommerce,
          setFirstChangeData,
          isRemoveCustomPropertyHaveFilter,
          setIsRemoveCustomPropertyHaveFilter,
          dataParamValue,
          isChangeScope,
          newDateRange,
          weight,
          setWeight,
          utmArray,
          setUtmArray,
          maxStepForm,
          setMaxStepForm,
          triggerClear,
          setTriggerClear,
          eCoItemCategories,
          isDirect,
          setIsDirect,
          attributeBeforeReload,
          setAttributeBeforeReload,
          listView,
          setListView,
          listViewByScoped,
          setListViewByScoped,
          viewDefault,
          setViewDefault,
          setFilterViewDefault,
          isMapView,
          setIsMapView,
          handleFindView,
          setIsHandleParams,
          setIsApplyMetric,
          viewLoading,
          tempReport,
          setTempReport,
     };
     return (
          <div className="analytics not-container">
               {conversionNameLoading ? (
                    <div className="page-loading-container">{!recordProfile && <CenterSpinner />}</div>
               ) : (
                    <EventExplorerContext.Provider value={contextValue}>
                         <div className="report-header d-flex align-items-center justify-content-between">
                              <p className="report-title">{title}</p>
                              <div className="d-flex align-items-center">
                                   {/* <div className='header-item'>
                            <CButton className='btn-add-segments'>Add Segments</CButton>
                        </div> */}
                                   {!recordProfile && (
                                        <>
                                             {reportType && (
                                                  <div className="header-item">
                                                       <ScopeDropdown />
                                                  </div>
                                             )}
                                             <div className="header-item">
                                                  <CustomDatePicker />
                                             </div>
                                        </>
                                   )}
                              </div>
                         </div>
                         {showChart && <ChartReport />}

                         <MenuReport />

                         <ReportTableV2 />
                    </EventExplorerContext.Provider>
               )}
          </div>
     );
};

EventExplorer.propTypes = {
     title: PropTypes.string,
     reportName: PropTypes.string,
};

export default EventExplorer;
