import React, {useEffect, useState} from 'react';
import CIcon from '@coreui/icons-react';
import ManageGoalItems from './ManageGoalItems';
import { useSelector } from 'react-redux';

function ManageGoal() {
    const activeListeners = useSelector((state) => state.subscriber.activeListeners);
    const nativeECommerce = useSelector((state) => state.subscriber.nativeECommerce);
    const [goalTypes, setGoalTypes] = useState([
        {
            id: 'a10fd244-5601-11ee-8c99-0242ac120002',
            name: 'Web Conversions',
            alias: 'web-conversions',
            code: 'webconversions',
            description:
                "Write rules to centrally define your website conversions. These power ListenLayer's Insights engine, and can be distributed to other destinations and marketing platforms (client side or server side).",
            status: true,
            created_at: '2020-10-22T03:05:47.279Z',
            updated_at: '2020-10-22T03:05:47.279Z',
            isNative: false,
            icon: 'iconCircleStartSolid',
            configStatus: true,
            information: 'Web Conversions are enabled automatically and cannot be turned off.',
        },
        {
            id: '677a6264-5602-11ee-8c99-0242ac120002',
            name: 'eCommerce',
            alias: 'ecommerce',
            code: 'ecommerce',
            description: 'Automate and customize your eCommerce goals to power insights, analytics, and distribute data where you need it. ',
            status: false,
            created_at: '2020-10-22T03:05:47.279Z',
            updated_at: '2020-10-22T03:05:47.279Z',
            isNative: false,
            icon: 'iconDollarSign',
            configStatus: true,
            information: 'Enable an eCommerce Listener to automatically turn on eCommerce Goals. ',
        },
        {
            id: 'b488846e-5602-11ee-8c99-0242ac120002',
            name: 'Web Triggers',
            alias: 'web-triggers',
            code: 'webtriggers',
            description: "Write trigger rules to send events and other KPIs (that aren't conversions) to destinations as data actions (client side or server side). ",
            status: true,
            created_at: '2020-10-22T03:05:47.279Z',
            updated_at: '2020-10-22T03:05:47.279Z',
            isNative: false,
            icon: 'cil-layers',
            configStatus: true,
            information: 'Web Triggers are enabled automatically and cannot be turned off.',
        },
        {
            id: 'b95cdf8a-5602-11ee-8c99-0242ac120002',
            name: 'Funnels',
            alias: 'web-funnels',
            code: 'funnels',
            description: "Write rules to turn a series of data layer events into funnels that you can analyze inside ListenLayer's Insights Engine.",
            status: false,
            created_at: '2020-10-22T03:05:47.279Z',
            updated_at: '2020-10-22T03:05:47.279Z',
            isNative: false,
            icon: 'iconFilterList',
            configStatus: false,
            comingSoon: true,
        },
        {
            id: 'c0b4ab1e-5602-11ee-8c99-0242ac120002',
            name: 'Form Categories',
            alias: 'form-categories',
            code: 'formcategories',
            description: "Categorize and name your forms to feed your Web Conversion strategy and generate automatic form engagement metrics in ListenLayer's Insights Engine. ",
            status: true,
            created_at: '2020-10-22T03:05:47.279Z',
            updated_at: '2020-10-22T03:05:47.279Z',
            isNative: false,
            icon: 'iconObjectColumn',
            configStatus: true,
            information: 'Form categorization and naming is enabled for all accounts.',
        },
        {
            id: 'c6604ff0-5602-11ee-8c99-0242ac120002',
            name: 'Sales Pipeline',
            alias: 'sales-pipeline',
            code: 'salespipeline',
            description:
                "Define your Sales Pipelines when you connect external data sources (such as a CRM). ListenLayer will include your external data and Sales Pipeline structure insight it's Insights Engine. ",
            status: false,
            created_at: '2020-10-22T03:05:47.279Z',
            updated_at: '2020-10-22T03:05:47.279Z',
            isNative: false,
            icon: 'iconArrowRightLong',
            configStatus: false,
            comingSoon: true,
        },
        {
            id: '4bb79a0a-5603-11ee-8c99-0242ac120002',
            name: 'Cohorts',
            alias: 'cohorts',
            code: 'Cohorts',
            description:
                'Write rules to create groups of Users, People, and Companies based on characteristcs and activity (including external data you connect to ListenLayer). Cohorts create powerful reports in the Insights Engine.',
            status: false,
            created_at: '2020-10-22T03:05:47.279Z',
            updated_at: '2020-10-22T03:05:47.279Z',
            isNative: false,
            icon: 'iconUserSolid',
            configStatus: false,
            comingSoon: true,
        },
        {
            id: '5466d5bc-5603-11ee-8c99-0242ac120002',
            name: 'Target Accounts',
            alias: 'target-accounts',
            code: 'targetaccounts',
            description:
                'Define lists of Target Accounts, companies you want to do business with. Pair this feature with Revealed Companies to learn when Users and People from these accounts engage your marketing and website. ',
            status: false,
            created_at: '2020-10-22T03:05:47.279Z',
            updated_at: '2020-10-22T03:05:47.279Z',
            isNative: false,
            icon: 'iconBuildingsSolid',
            configStatus: false,
            comingSoon: true,
        },
        {
            id: '5a5759f6-5603-11ee-8c99-0242ac120002',
            name: 'Competitors',
            alias: 'competitors',
            code: 'competitors',
            description:
                "Create lists of your competitors based on domain name. Pair this feature with Revealed Companies to learn when your competitors are visiting your website (and what they're viewing)...",
            status: false,
            created_at: '2020-10-22T03:05:47.279Z',
            updated_at: '2020-10-22T03:05:47.279Z',
            isNative: false,
            icon: 'iconCalendarExclamation',
            configStatus: false,
            comingSoon: true,
        },
    ]);

    useEffect(() => {
        const checkEcommerceStatus = activeListeners.some((item) => ['Ecommerce', 'eCommerce'].includes(item.type)) || nativeECommerce;
    
        setGoalTypes((prevGoalTypes) =>
            prevGoalTypes.map((goal) => {
                if (goal.name === 'eCommerce') {
                    return { ...goal, status: checkEcommerceStatus };
                }
                return goal;
            })
        );
        
    }, [activeListeners]); //eslint-disable-line
    

    return (
        <>
            <div className='listener-title headline-wrapper'>
                <h1>Goals</h1>
                <div className='description'>
                    Manage the types of goals you want to use inside your account. Goals allow you to define rules for important action, characteristics, and KPIs you care about.
                </div>
            </div>
            {goalTypes.length > 0 ? (
                <ManageGoalItems goalTypes={goalTypes} />
            ) : (
                <div className='no-filtering-result'>
                    <h2 className='d-flex justify-content-center align-items-center'>
                        No Goal Types <CIcon name='cil-ban' width='30' className='icon-cil-ban' />
                    </h2>
                </div>
            )}
        </>
    );
}

export default ManageGoal;
