import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CButton, CCard, CCardBody, CImg } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-solarized_light';
import 'ace-builds/src-noconflict/ext-language_tools';

import { CHANGE_STEP_TYPE, CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';
import { INSTALLATION_SCRIPT_VIDEO } from '../../../../../../constants';
import { InstructionPopup } from '../../../../../general/popup';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getDLScript } from '../../../../../../utils';

const PlaceScriptManually = () => {
    const { account, setCurrentStep, gtmTagTriggerName } = useContext(CreateAccountContext);
    const [showInstructionPopup, setShowInstructionPopup] = useState(false);
    const theScript = getDLScript(account.id);
    const [firstCopy, setFirstCopy] = useState(false);

    const onBackBtnClicked = () => {
        setCurrentStep(STEPS.gtmConnection);
    };

    const toggleInstructionPopup = useCallback(() => {
        setShowInstructionPopup(show => !show);
    }, []);

    const placedTheCode = useCallback(() => {
        setCurrentStep(STEPS.verifyingScript);
    }, [setCurrentStep]);

    const placeScriptLater = useCallback(() => {
        setCurrentStep(STEPS.skipPlacingScript, CHANGE_STEP_TYPE.subStepNext);
    }, [setCurrentStep]);


    const copyFirstScript = useCallback(() => {
        setFirstCopy(true);
    }, []);

    useEffect(() => {
        if (firstCopy) {
            setTimeout(() => {
                setFirstCopy(false);
            }, 2000);
        }
    }, [firstCopy]);

    return (
        <CreateAccountBody onBackBtnClicked={onBackBtnClicked}>
            <CCard className="account-script-manually">
                <p className="number-of-step">2 of 3 steps</p>
                <CCardBody>
                    <h3>Place your script manually</h3>
                    <p>
                        This code needs to be placed inside the &lt;head&gt; of every page of your website. We recommend creating a tag in Google Tag
                        manager and setting the trigger to <strong>{gtmTagTriggerName}</strong>.
                    </p>
                    <div className="script-code">
                        <div className="d-flex align-items-center copy-script">
                            {firstCopy ? <div className="icon-copy">Copied</div> : <div className="icon-copy">Click to Copy</div>}
                            <CopyToClipboard text={theScript} onCopy={copyFirstScript}>
                                <CButton>
                                    <CImg src="/assets/images/icon-copy-v2.png" height="19" width="19" />
                                </CButton>
                            </CopyToClipboard>
                        </div>
                        <div className="script">
                            <AceEditor
                                placeholder="No Code Preview"
                                mode="html"
                                theme="solarized_light"
                                name="blah2"
                                fontSize={13}
                                width="473px"
                                height="164px"
                                showPrintMargin={true}
                                showGutter={false}
                                highlightActiveLine={false}
                                readOnly={true}
                                value={theScript}
                                wrapEnabled={true}
                                setOptions={{
                                    enableBasicAutocompletion: false,
                                    enableLiveAutocompletion: false,
                                    enableSnippets: false,
                                    showLineNumbers: false,
                                    useWorker: false,
                                }}
                            />
                        </div>
                    </div>
                    <CButton className="btn-save" onClick={placedTheCode}>
                        I’VE PLACED THE CODE
                    </CButton>
                    <CButton className="btn-save btn-help" onClick={toggleInstructionPopup}>
                        <CIcon name="arrowPlayRadius" height={50} />I NEED HELP
                    </CButton>
                    <CButton className="btn-navigation-step" onClick={placeScriptLater}>
                        <span>I’ll place my script later</span>
                        <i className="fal fa-angle-right"></i>
                    </CButton>
                </CCardBody>
            </CCard>
            <InstructionPopup
                show={showInstructionPopup}
                className={'script-manually'}
                onClose={toggleInstructionPopup}
                videoEmbed={INSTALLATION_SCRIPT_VIDEO}
            />
        </CreateAccountBody>
    );
};

export default PlaceScriptManually;
