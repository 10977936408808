import React, { useContext, useMemo, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { CPagination, CRow, CSwitch } from '@coreui/react';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { encodeObj } from '../../../../utils';
import { EventExplorerContext } from './EventExplorer';
import { useSelector } from 'react-redux';
import { DEFAULT_DATE_FORMAT, REPORT_DATA_TYPES, REPORT_NAMES, REPORT_TAB_TYPES, SUBSCRIBER_PATH, VARIABLE_OPERATORS_DIMENSION_REPORT } from '../../../../constants';
import { formatNumber, handleChangeCol, handleChangeColSort, isNumber } from '../../../../utils/UtilReport';
import { LoadingPanel } from './report-v2/LoadingTemplate';
import CustomHeaderCell from './report-v2/grid/CustomHeaderCell';

const ReportTableCompare = (props) => {
     const {
          reportName,
          filterDimensions,
          setFilterDimensions,
          filterProperties,
          setFilterProperties,
          isLoading,
          scoped,
          reportType,
          reportData,
          reportCompare,
          itemsPerPage,
          setItemsPerPage,
          dateRangeConfig,
          conversionNameMetric,
          filterMetric,
          setFilterMetric,
          setOffSet,
          eCoItemCategories,
          utmArray,
          weight,
          handleFindView
     } = useContext(EventExplorerContext);
     let { dimensions, metrics } = reportType ? REPORT_DATA_TYPES[reportName][scoped] : REPORT_DATA_TYPES[reportName];
     if (reportName === REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT) {
          dimensions = [...dimensions, ...utmArray];
     }
     if (reportName === REPORT_NAMES.ECOMMERCE_ITEMS) {
          dimensions = [...dimensions, ...eCoItemCategories];
     }
     const newDimensions = handleChangeCol(dimensions, filterDimensions);
     const newMetric = handleChangeColSort([...conversionNameMetric, ...metrics], filterMetric);
     const widthColumnReports = useSelector((state) => state.subscriber.widthColumnReports);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const {
          getWidthByKey,
          tableGrid,
          handleColumnResize,
          lock,
          activePage,
          setActivePage,
          arrShowShortIds,
          showLock,
          showShortId,
          sort,
          disableSort,
          setLock,
          setShowShortId,
     } = props;
     const [pagination, setPagination] = useState(true);
     const [tableData, setTableData] = useState([]);
     const totalPages = Math.ceil(reportData.overallRowCount / itemsPerPage) || 1;

     const tableCompare = useMemo(() => {
          // Transform reportData.rows into suitable data for CDataTable
          // Each row will be {key1: value1, key2: value2, ...}
          if (dateRangeConfig.comparePeriod) {
               let returnRows = [];
               if (reportCompare.rows) {
                    reportCompare.rows.forEach((row) => {
                         const dimensionValue = row.dimensionCompoundValues;
                         Object.entries(row).forEach((value) => {
                              let fieldValues = {};
                              switch (value[0]) {
                                   case 'dimensionCompoundValues':
                                        fieldValues = value[1].reduce((prevVal, currentVal, i) => {
                                             if (i <= newDimensions.length - 1) {
                                                  return { ...prevVal, [newDimensions[i].key]: currentVal.value };
                                             } else {
                                                  return {};
                                             }
                                        }, {});
                                        returnRows.push(fieldValues);
                                        break;
                                   case 'metricCompoundValues':
                                        Object.entries(value[1]).forEach((item) => {
                                             let dateValue = { date: item[0] };
                                             let fieldDimension = dimensionValue.reduce((prevVal, currentVal, i) => {
                                                  if (i <= newDimensions.length - 1) {
                                                       return { ...prevVal, [newDimensions[i].key]: currentVal.value };
                                                  } else {
                                                       return {};
                                                  }
                                             }, {});
                                             let dimensions = { dimension: fieldDimension };
                                             fieldValues = item[1].reduce((prevVal, currentVal, i) => {
                                                  if (i <= newMetric.length - 1) {
                                                       return { ...prevVal, [newMetric[i].key]: currentVal.value };
                                                  } else {
                                                       return {};
                                                  }
                                             }, {});
                                             returnRows.push({ ...fieldValues, ...dateValue, ...dimensions });
                                        });
                                        break;
                                   case 'propertyCompoundValues':
                                        break;
                                   default:
                                        break;
                              }
                         });
                    });
                    return returnRows;
               }
          }
     }, [reportCompare.rows]); // eslint-disable-line

     useEffect(() => {
          if (tableCompare) {
               setTableData([...tableCompare]);
          }
     }, [tableCompare]);

     const handleChangePagination = () => {
          setPagination(!pagination);
          if (pagination) {
               setItemsPerPage(7);
          }

          setActivePage(1);
          setOffSet(0);
     };

     const handleLinkToOtherReport = (fieldKey, linkedData) => {
          let path = '';
          let linkedReportData;
          let filters = [];

          const dateClick = linkedData.date.split('-');
          const startDate = dayjs(dateClick[0]).format(DEFAULT_DATE_FORMAT);
          const endDate = dayjs(dateClick[1]).format(DEFAULT_DATE_FORMAT);

          switch (fieldKey) {
               case 'sessionCount':
                    path = SUBSCRIBER_PATH.INSIGHTS_SESSIONS;
                    newDimensions.forEach((item) => {
                         filters.push({
                              type: item.key,
                              value: `${linkedData['dimension'][item.key]}`,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                              dateRange: {
                                   startDate,
                                   endDate,
                              },
                              utmArray: utmArray.filter((el) => el.key === item.key),
                              weight: weight,
                         });
                    });
                    break;

               case 'userCount':
                    path = SUBSCRIBER_PATH.INSIGHTS_USERS;
                    newDimensions.forEach((item) => {
                         filters.push({
                              type: item.key,
                              value: `${linkedData['dimension'][item.key]}`,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                         });
                    });

                    break;

               case 'peopleCount':
                    path = SUBSCRIBER_PATH.INSIGHTS_PEOPLE;
                    newDimensions.forEach((item) => {
                         filters.push({
                              type: item.key,
                              value: `${linkedData['dimension'][item.key]}`,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                         });
                    });

                    break;

               case 'revealedCompany':
                    path = SUBSCRIBER_PATH.INSIGHTS_REVEALED_COMPANIES;
                    newDimensions.forEach((item) => {
                         filters.push({
                              type: item.key,
                              value: `${linkedData['dimension'][item.key]}`,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                         });
                    });

                    break;
               default:
          }

          linkedReportData = {
               filters,
               dateRange: {
                    startDate,
                    endDate,
               },
               dateScope: dateRangeConfig.dateScope,
          };

          path = path.replace(':secondId', activeAccount.secondId) + '?data=' + encodeObj(linkedReportData);
          window.open(path, '_blank');
     };

     function CustomCellCompare(props, index) {
          const value = props.dataItem[props.field];
          const noFormat = ['itemId', 'transactionId', 'phone', 'utmContent', 'utmTerm', 'utmCampaign', 'utmMedium', 'utmSource', 'postalCodeCustom']
          const { style, className, id } = props;
          if (value === undefined) {
               if (index === 0)
                    return (
                         <td
                              {...{ style, className }}
                              tabIndex={0}
                              data-keyboardnavid={id}
                              colSpan={filterDimensions.length}
                              style={{ textAlign: 'center' }}
                         >
                              {props.dataItem['date']}
                         </td>
                    );
               else
                    return (
                         <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id} style={{ display: 'none' }}>
                              {props.dataItem['date']}
                         </td>
                    );
          } else {
               return typeof value === 'boolean' || noFormat.includes(props.field) ? (
                    <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                         <span>{String(value)}</span>
                    </td>
               ) : (
                    <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                         {' '}
                         {`${formatNumber(value)}`}
                    </td>
               );
          }
     }

     const handleEnterToLink = (e) => {
          if (e.which === 13) e.currentTarget.click();
     };

     const SessionCount = (data, { style, className, id }) => {
          const { sessionCount } = data;
          if (sessionCount > 0)
               return (
                    <td
                         style={style}
                         className={`clickable ${className}`}
                         tabIndex={0}
                         data-keyboardnavid={id}
                         onKeyPress={(e) => handleEnterToLink(e)}
                         onClick={() => {
                              return handleLinkToOtherReport('sessionCount', { ...data });
                         }}
                    >
                         {`${formatNumber(sessionCount)}`}
                    </td>
               );
          else
               return (
                    <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                         {sessionCount ? formatNumber(sessionCount) : 0}{' '}
                    </td>
               );
     };

     const UserCount = (data, { style, className, id }) => {
          const { userCount } = data;
          if (userCount > 0) {
               return (
                    <td
                         style={style}
                         className={`clickable ${className}`}
                         tabIndex={0}
                         data-keyboardnavid={id}
                         onKeyPress={(e) => handleEnterToLink(e)}
                         onClick={() => {
                              return handleLinkToOtherReport('userCount', { ...data });
                         }}
                    >
                         {`${formatNumber(userCount)}`}
                    </td>
               );
          } else {
               return (
                    <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                         {userCount ? formatNumber(userCount) : 0}{' '}
                    </td>
               );
          }
     };

     const PeopleCount = (data, { style, className, id }) => {
          const { peopleCount } = data;
          if (peopleCount > 0) {
               return (
                    <td
                         style={style}
                         className={`clickable ${className}`}
                         tabIndex={0}
                         data-keyboardnavid={id}
                         onKeyPress={(e) => handleEnterToLink(e)}
                         onClick={() => {
                              return handleLinkToOtherReport('peopleCount', { ...data });
                         }}
                    >
                         {`${formatNumber(peopleCount)}`}
                    </td>
               );
          } else {
               return (
                    <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                         {peopleCount ? formatNumber(peopleCount) : 0}{' '}
                    </td>
               );
          }
     };

     const RevealedCompany = (data, { style, className, id }) => {
          const { revealedCompany } = data;
          if (revealedCompany > 0) {
               return (
                    <td
                         style={style}
                         className={`clickable ${className}`}
                         tabIndex={0}
                         data-keyboardnavid={id}
                         onKeyPress={(e) => handleEnterToLink(e)}
                         onClick={() => {
                              return handleLinkToOtherReport('revealedCompany', { ...data });
                         }}
                    >
                         {`${formatNumber(revealedCompany)}`}
                    </td>
               );
          } else {
               return (
                    <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                         {revealedCompany ? formatNumber(revealedCompany) : 0}{' '}
                    </td>
               );
          }
     };

     function CustomCellCompareValue(props) {
          const value = props.dataItem[props.field] !== undefined ? props.dataItem[props.field] : 0;
          const { style, className, id } = props;

          if (!props.dataItem['date']) {
               return <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}></td>;
          }

          if (props.field.includes('Rate')) {
               return <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>{`${formatNumber(value)}%`}</td>;
          }

          switch (props.field) {
               case 'avgSessionDuration':
               case 'sessionDuration':
                    return (
                         <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                              {value}
                         </td>
                    );
               case 'avgVisibleTimeOnPage':
                    return (
                         <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                              {value ? value : 0}
                         </td>
                    );
               case 'avgVisibleTimePerPage':
                    return (
                         <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                              {value}
                         </td>
                    );

               case 'sessionCount':
                    return SessionCount(props.dataItem, props);

               case 'userCount':
                    return UserCount(props.dataItem, props);

               case 'peopleCount':
                    return PeopleCount(props.dataItem, props);

               case 'revealedCompany':
                    return RevealedCompany(props.dataItem, props);

               default:
                    return typeof value === 'boolean' ? (
                         <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                              <span>{String(value)}</span>
                         </td>
                    ) : (
                         <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                              {' '}
                              {`${formatNumber(value)}`}
                         </td>
                    );
          }
     }

     const getTotalCellCompare = (field) => {
          const totalValue = reportCompare.totalsRow.totalNor;
          const totalCompare = reportCompare.totalsRow.totalCompare;
          if ((totalValue && totalValue.length > 0) || (totalCompare && totalCompare.length > 0)) {
               const fieldDuration = field.includes('Duration') || field.includes('VisibleTime');
               const totalCell = totalValue ? totalValue[0].metricCompoundValues.find((item) => item.key === field) : { value: !fieldDuration ? 0 : `0m 0s` };
               const totalCellCompare = totalCompare ? totalCompare[0].metricCompoundValues.find((item) => item.key === field) : { value: !fieldDuration ? 0 : `0m 0s` };
               if (totalCell) {
                    if (!isNumber(totalCell.value) || !isNumber(totalCellCompare.value)) {
                         return `${totalCell.value} vs ${totalCellCompare.value}`;
                    } else if (field.includes('Rate')) {
                         return `${formatNumber(totalCell.value)}% vs ${formatNumber(totalCellCompare.value)}%`;
                    } else {
                         return `${formatNumber(totalCell.value)} vs ${formatNumber(totalCellCompare.value)}`;
                    }
               }
          }
     };

     const onChangeItemsPerPage = (e) => {
          setActivePage(1);
          setOffSet(0);
          setItemsPerPage(+e.target.value);
     };

     const onPaginationChange = (i) => {
          setOffSet((i - 1) * itemsPerPage)
          setActivePage(i);
     };

     const mergeMetrics = useMemo(() => [...filterMetric], [filterMetric])

     const handleColumnReorder = ({ columns, columnId }) => {
          columns.forEach(column => {
               column.children.some(child => {
                    if (child.id === columnId) {
                         const sortedChildren = column.children.sort((a, b) => a.orderIndex - b.orderIndex);
          
                         const reorderedColumns = sortedChildren.map(child => ({
                              label: child.title,
                              value: child.field,
                         }));
          
                         switch (column.title) {
                              case 'Dimensions':
                                   setFilterDimensions(reorderedColumns);
                                   handleFindView(REPORT_TAB_TYPES.DIMENSIONS, reorderedColumns, false);
                                   break;
                              case 'Properties':
                                   setFilterProperties(reorderedColumns);
                                   handleFindView(REPORT_TAB_TYPES.PROPERTIES, reorderedColumns, false);
                                   break;
                              case 'Metrics':
                                   setFilterMetric(reorderedColumns);
                                   handleFindView(REPORT_TAB_TYPES.METRICS, reorderedColumns, false);
                                   break;
                              default:
                                   break;
                         }
          
                         return true;
                    }

                    return false;
               });
          });
     }

     return (
          <>
               {isLoading && pagination && <LoadingPanel />}
               <Grid
                    ref={tableGrid}
                    style={{ width: 'fit-content', maxWidth: '100%', maxHeight: pagination ? '1500px' : '800px' }}
                    data={tableData.length === 0 && isLoading ? Array(5).fill({}) : tableData}
                    resizable={true}
                    reorderable={true}
                    navigatable={true}
                    scrollable="scrollable"
                    onColumnReorder={handleColumnReorder}
                    onColumnResize={handleColumnResize}
                    // rowRender={isLoading && SkeletonLoader}
               >
                    <GridNoRecords>No results found</GridNoRecords>

                    {filterDimensions.length > 0 && (
                         <GridColumn orderIndex={0} title="Dimensions" resizable={false}>
                              {filterDimensions.map((item, index) => {
                                   return (
                                        <GridColumn
                                             key={index}
                                             title={item.label}
                                             field={item.value}
                                             orderIndex={index}
                                             width={getWidthByKey(
                                                  widthColumnReports,
                                                  reportType,
                                                  reportName,
                                                  item.label,
                                                  item.value,
                                                  tableData,
                                                  index
                                             )}
                                             headerCell={(props) => (
                                                  <CustomHeaderCell
                                                       props={props}
                                                       dimensions={dimensions}
                                                       arrShowShortIds={arrShowShortIds}
                                                       showLock={showLock}
                                                       lock={lock}
                                                       showShortId={showShortId}
                                                       sort={sort}
                                                       disableSort={disableSort}
                                                       setLock={setLock}
                                                       setShowShortId={setShowShortId}
                                                  />
                                             )}
                                             cell={(props) => CustomCellCompare(props, index)}
                                             locked={lock[item.value] ? true : false}
                                             footerCell={(props) => {
                                                  if (index + 1 === filterDimensions.length + filterProperties.length) {
                                                       return (
                                                            <td
                                                                 className={props.className}
                                                                 style={{ ...props.style, textAlign: 'right', borderLeft: 'none' }}
                                                            >
                                                                 Totals
                                                            </td>
                                                       );
                                                  } else {
                                                       return <td className={props.className} style={{ ...props.style, borderLeft: 'none' }}></td>;
                                                  }
                                             }}
                                        ></GridColumn>
                                   );
                              })}
                         </GridColumn>
                    )}
                    {filterProperties.length > 0 && (
                         <GridColumn orderIndex={1} title="Properties" resizable={false}>
                              {filterProperties.map((item, index) => {
                                   return (
                                        <GridColumn
                                             key={index}
                                             title={item.label}
                                             field={item.value}
                                             orderIndex={index}
                                             width={getWidthByKey(widthColumnReports, reportType, reportName, item.label)}
                                             headerCell={(props) => (
                                                  <CustomHeaderCell
                                                       props={props}
                                                       dimensions={dimensions}
                                                       arrShowShortIds={arrShowShortIds}
                                                       showLock={showLock}
                                                       lock={lock}
                                                       showShortId={showShortId}
                                                       sort={sort}
                                                       disableSort={disableSort}
                                                       setLock={setLock}
                                                       setShowShortId={setShowShortId}
                                                  />
                                             )}
                                             cell={(props) => CustomCellCompareValue(props, index)}
                                             locked={lock[item.value] ? true : false}
                                             footerCell={(props) => {
                                                  // if (colLockCount < filterProperties.length + filterProperties.length && index + 1 >= colLockCount) return <td></td>;
                                                  // else return '';
                                                  if (index + 1 === filterProperties.length) {
                                                       return (
                                                            <td
                                                                 className={props.className}
                                                                 style={{ ...props.style, textAlign: 'right', borderLeft: 'none' }}
                                                            >
                                                                 Totals
                                                            </td>
                                                       );
                                                  } else {
                                                       return <td className={props.className} style={{ ...props.style, borderLeft: 'none' }}></td>;
                                                  }
                                             }}
                                        ></GridColumn>
                                   );
                              })}
                         </GridColumn>
                    )}

                    {mergeMetrics.length > 0 && (
                         <GridColumn orderIndex={2} title="Metrics" resizable={false}>
                              {mergeMetrics.map((item, index) => {
                                   return (
                                        <GridColumn
                                             key={index}
                                             title={item.label}
                                             field={item.value}
                                             orderIndex={index}
                                             width={getWidthByKey(widthColumnReports, reportType, reportName, item.label)}
                                             headerCell={(props) => (
                                                  <CustomHeaderCell
                                                       props={props}
                                                       dimensions={dimensions}
                                                       arrShowShortIds={arrShowShortIds}
                                                       showLock={showLock}
                                                       lock={lock}
                                                       showShortId={showShortId}
                                                       sort={sort}
                                                       disableSort={disableSort}
                                                       setLock={setLock}
                                                       setShowShortId={setShowShortId}
                                                  />
                                             )}
                                             locked={lock[item.value] ? true : false}
                                             cell={(props) => CustomCellCompareValue(props, index)}
                                             footerCell={(props) => {
                                                  const totalCell = getTotalCellCompare(props.field);
                                                  return (
                                                       <td className={props.className} style={props.style} width={props.width}>
                                                            {totalCell || totalCell === 0 ? totalCell : ''}
                                                       </td>
                                                  );
                                             }}
                                        ></GridColumn>
                                   );
                              })}
                         </GridColumn>
                    )}
                    {/* {convRate.length > 0 && (
                         <GridColumn orderIndex={3} title={`${scoped} Conv Rates`} resizable={false}>
                              {convRate.map((item, index) => {
                                   return (
                                        <GridColumn
                                             key={index}
                                             title={item.label}
                                             field={item.value}
                                             width={getWidthByKey(widthColumnReports, reportType, reportName, item.label)}
                                             headerCell={(props) => (
                                                  <CustomHeaderCell
                                                       props={props}
                                                       dimensions={dimensions}
                                                       arrShowShortIds={arrShowShortIds}
                                                       showLock={showLock}
                                                       lock={lock}
                                                       showShortId={showShortId}
                                                       sort={sort}
                                                       disableSort={disableSort}
                                                       setLock={setLock}
                                                       setShowShortId={setShowShortId}
                                                  />
                                             )}
                                             locked={lock[item.value] ? true : false}
                                             cell={(props) => CustomCellCompareValue(props, index)}
                                             footerCell={(props) => {
                                                  const totalCell = getTotalCellCompare(props.field);
                                                  return (
                                                       <td className={props.className} style={props.style} width={props.width}>
                                                            {totalCell || totalCell === 0 ? totalCell : ''}
                                                       </td>
                                                  );
                                             }}
                                        ></GridColumn>
                                   );
                              })}
                         </GridColumn>
                    )}
                    {convName.length > 0 && (
                         <GridColumn orderIndex={3} title={'Individual Conversions'} resizable={false}>
                              {convName.map((item, index) => {
                                   return (
                                        <GridColumn
                                             key={index}
                                             title={item.label}
                                             field={item.value}
                                             width={getWidthByKey(widthColumnReports, reportType, reportName, item.label)}
                                             headerCell={(props) => (
                                                  <CustomHeaderCell
                                                       props={props}
                                                       dimensions={dimensions}
                                                       arrShowShortIds={arrShowShortIds}
                                                       showLock={showLock}
                                                       lock={lock}
                                                       showShortId={showShortId}
                                                       sort={sort}
                                                       disableSort={disableSort}
                                                       setLock={setLock}
                                                       setShowShortId={setShowShortId}
                                                  />
                                             )}
                                             locked={lock[item.value] ? true : false}
                                             cell={(props) => CustomCellCompareValue(props, index)}
                                             footerCell={(props) => {
                                                  const totalCell = getTotalCellCompare(props.field);
                                                  return (
                                                       <td className={props.className} style={props.style} width={props.width}>
                                                            {totalCell || totalCell === 0 ? totalCell : ''}
                                                       </td>
                                                  );
                                             }}
                                        ></GridColumn>
                                   );
                              })}
                         </GridColumn>
                    )}
                    {eCommerce.length > 0 && (
                         <GridColumn orderIndex={3} title={'Individual Conversions'} resizable={false}>
                              {eCommerce.map((item, index) => {
                                   return (
                                        <GridColumn
                                             key={index}
                                             title={item.label}
                                             field={item.value}
                                             width={getWidthByKey(widthColumnReports, reportType, reportName, item.label)}
                                             headerCell={(props) => (
                                                  <CustomHeaderCell
                                                       props={props}
                                                       dimensions={dimensions}
                                                       arrShowShortIds={arrShowShortIds}
                                                       showLock={showLock}
                                                       lock={lock}
                                                       showShortId={showShortId}
                                                       sort={sort}
                                                       disableSort={disableSort}
                                                       setLock={setLock}
                                                       setShowShortId={setShowShortId}
                                                  />
                                             )}
                                             locked={lock[item.value] ? true : false}
                                             cell={(props) => CustomCellCompareValue(props, index)}
                                             footerCell={(props) => {
                                                  const totalCell = getTotalCellCompare(props.field);
                                                  return (
                                                       <td className={props.className} style={props.style} width={props.width}>
                                                            {totalCell || totalCell === 0 ? totalCell : ''}
                                                       </td>
                                                  );
                                             }}
                                        ></GridColumn>
                                   );
                              })}
                         </GridColumn>
                    )} */}
               </Grid>
               <CRow className={`report-table-bottom ${!pagination ? 'justify-content-end' : ''}`}>
                    {pagination && (
                         <CRow>
                              <CPagination
                                   activePage={activePage}
                                   pages={totalPages}
                                   onActivePageChange={onPaginationChange}
                                   className="report-table-page"
                              ></CPagination>
                              <div className="form-inline justify-content-sm-end ml-4">
                                   <select className="form-control" onChange={onChangeItemsPerPage} defaultValue={itemsPerPage}>
                                        <option value="7">7</option>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                   </select>
                                   <label className="ml-2">Item per page</label>
                              </div>
                         </CRow>
                    )}
                    {!pagination && (
                         <div className="toggle-bottom">
                              <CSwitch
                                   className="mx-1"
                                   variant="3d"
                                   color="primary"
                                   checked={pagination ? 'checked' : ''}
                                   onChange={(e) => handleChangePagination()}
                                   disabled={true}
                              />
                              <span>Paging</span>
                         </div>
                    )}
               </CRow>
          </>
     );
};

export default React.memo(ReportTableCompare);
