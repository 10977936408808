import React, { useCallback, useEffect, useState } from 'react';
import { CButton, CImg, CModal, CModalBody } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-language_tools';
import CopyToClipboard from 'react-copy-to-clipboard';

const GetScript = ({ show, onClose, script }) => {
	const [firstCopy, setFirstCopy] = useState(false);

	const copyFirstScript = useCallback(() => {
		setFirstCopy(true);
	}, []);

	useEffect(() => {
		if (firstCopy) {
			setTimeout(() => {
				setFirstCopy(false);
			}, 2000);
		}
	}, [firstCopy]);

    return (
        <div className="get-script-popup">
            <CModal show={show} onClose={onClose} color="primary" centered>
                <CModalBody className="text-center">
                    <div className="script-code">
                        <div className="d-flex align-items-center copy-script">
                            {firstCopy ? <div className="icon-copy">Copied</div> : <div className="icon-copy">Click to Copy</div>}
                            <CopyToClipboard text={script} onCopy={copyFirstScript}>
                                <CButton>
                                    <CImg src="/assets/images/icon-copy-v2.png" height="19" width="19" />
                                </CButton>
                            </CopyToClipboard>
                            <CIcon name="cil-x" onClick={onClose} className="icon-close-popup"></CIcon>
                        </div>
                        <div className="script">
                            <AceEditor
                                placeholder="No Code Preview"
                                mode="javascript"
                                theme="tomorrow"
                                name="blah2"
                                fontSize={13}
                                width="100%"
                                height="130px"
                                showPrintMargin={false}
                                showGutter={false}
                                highlightActiveLine={false}
                                readOnly={true}
                                value={script}
                                wrapEnabled={true}
                                setOptions={{
                                    enableBasicAutocompletion: false,
                                    enableLiveAutocompletion: false,
                                    enableSnippets: false,
                                    showLineNumbers: false,
                                    useWorker: false,
                                }}
                            />
                        </div>
                    </div>
                </CModalBody>
            </CModal>
        </div>
    );
};

GetScript.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    script: PropTypes.string
};

export default GetScript;
