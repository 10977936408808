import { CButton, CCard, CCardBody } from '@coreui/react';
import React, { useContext } from 'react';
import { SWITCH_TYPE_WIZARD_STEP } from '../../../../../../constants';
import Waiting from '../../../../../general/Loadings/Waiting';
import { SwitchTypeWizardBody, SwitchTypeWizardContext } from '../SwitchTypeWizard';
import { useSelector } from 'react-redux';

const Migrate = () => {
     const { setCurrentStep, handleUpdateSwitchType, isGTM, isEither, isServerSide } = useContext(SwitchTypeWizardContext);
     const switchTypeWizard = useSelector((state) => state.subscriber.switchTypeWizard);
     const sendTo = switchTypeWizard.sendTo

     const onBackBtnClicked = () => {
          setCurrentStep(SWITCH_TYPE_WIZARD_STEP.getStarted);
          handleUpdateSwitchType({ initStep: SWITCH_TYPE_WIZARD_STEP.getStarted });
     };

     const goToNextStep = () => {
          const nextStep = SWITCH_TYPE_WIZARD_STEP.migrateMakeChanges;
          if(!(isEither && sendTo)) {
               handleUpdateSwitchType({ initStep: nextStep });
          } else {
               if (typeof switchTypeWizard.data.doNext === 'function') {
                    switchTypeWizard.data.doNext();
               }
          }
          setCurrentStep(nextStep);
     };

     const getContent = () => {
          let content = {}

          if(isGTM || (isEither && sendTo === 'gtm')) {
               content.heading = 'Client Side Tagging' 
               content.subHeading = `you'll need to send your configuration to GTM.`
          }

          if(isServerSide || (isEither && sendTo === 'server')) {
               content.heading = 'Server Side Tagging' 
               content.subHeading = `you'll be provided options for how to clean up your old tags.`
          }

          if(isEither && sendTo === 'either') {
               content.heading = 'Server Side tagging or Client Site tagging' 
               content.subHeading = `you'll be provided options for how to clean up your old tags.`  
          }

          if(!content.heading){
               content.heading = isGTM ? 'Client Side Tagging' : 'Server Side Tagging'
          }

          if(!content.subHeading){
               content.subHeading = isGTM ? `you'll need to send your configuration to GTM.` : `you'll be provided options for how to clean up your old tags.`
          }

          return content
     }

     const contentMigrate = getContent()

     return (
          <SwitchTypeWizardBody showCancelProcess={true} onBackBtnClicked={onBackBtnClicked}>
               <CCard className='migrate'>
                    <CCardBody>
                         <h3>Migrate Your Data Actions</h3>
                         <p className='des'>
                              We'll convert your existing Data Actions in ListenLayer to utilize {contentMigrate.heading}. This process will happen
                              automatically on the next screen. After it is complete,{' '}
                              {contentMigrate.subHeading}
                         </p>
                         <CButton className='btn-save' type='submit' style={{ width: '40%' }} onClick={goToNextStep}>
                              <Waiting>CHANGE DATA ACTIONS</Waiting>
                         </CButton>
                    </CCardBody>
               </CCard>
          </SwitchTypeWizardBody>
     );
};

export default Migrate;
